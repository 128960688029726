import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Input } from "../Input/input";
import style from "../../pages/home/App.module.scss";
import { Select } from "../Select/select";
import { validateCPF, validateCRM, validateCountryState, validateEmail, validatePassword, validatePhone, validateRecoveryEmail } from "../../utils/validators";
import { useAppDispatch } from "../../redux/store";
import { useState } from "react";
import imgSignature from "../../assets/img/mobilemed-assinatura.jpg"
import { StateSelect } from "../StateSelect/stateSelect";
import { setAdminState } from "../../redux/admin/admin-slice";
import { AdminStateEnum } from "../../redux/classes/AdminState";
import { getUserPools } from "../../redux/pool/pool-thunks";
import { Checkbox } from "../Checkbox/checkbox";
import { createExternalUser, createUser, getUserStates, updateSignature, updateUser, updateUserPhoto } from "../../redux/user/user-thunks";
import { CreateUser } from "../../DTOs/CreateUser";
import { SignPhoto } from "../SignPhoto/signPhoto";
import { toast } from "react-toastify";
import { ProfilePhoto } from "../ProfilePhoto/profilePhoto";
import AdminUpdatePassword from "../AdminUpdatePassword/AdminUpdatePassword";
import { UpdateUser } from "../../DTOs/UpdateUser";
import decodeJWT from "../../utils/jwt-decoder";
import { useNavigate } from "react-router-dom";
import Button, { ButtonColors, ButtonTypes } from "../Button";
import AdminRemoveMFA from "../AdminRemoveMFA/AdminRemoveMFA";
import AdminLogs from "../AdminLogs/AdminLogs";
import AdminApplications from "../AdminApplications/AdminApplications";
import { UserService } from "../../services/UsersService";
import { InputDate } from "../InputDate/inputDate";

type RegisterProps = {
  userFormMode: UserFormModeEnum;
  user?: any;
};

export enum UserFormModeEnum {
  REGISTER = "REGISTER",
  ADMIN_REGISTER = "ADMIN_REGISTER",
  EDIT = "EDIT",
  ADMIN_EDIT = "ADMIN_EDIT"
}

function UserForm({ userFormMode, user }: RegisterProps) {
  // Redux
  const dispatch = useAppDispatch();
  const pool = useSelector((state: any) => state.poolReducer);
  const userSlice = useSelector((state: any) => state.userReducer);
  const states: any = useSelector((state: any) => state.userReducer.states.states);
  const statesLoading: any = useSelector((state: any) => state.userReducer.userStateloading);
  const statesError: any = useSelector((state: any) => state.userReducer.userStateError);
  const navigate = useNavigate();

  function setEmailAsVerified() {
    UserService.setEmailAsVerified(user.id)
      .then(() => {
        toast.success("E-mail verificado com sucesso!")
        return backToList()
      }).catch(() => {
        toast.error("Erro ao marcar o e-mail como verificado!")
      })
  }

  if (!pool || (!pool.loading && !pool.pools.length) && userFormMode !== UserFormModeEnum.REGISTER && userFormMode !== UserFormModeEnum.EDIT) {
    dispatch(getUserPools())
  }

  //Form
  const form = useForm<any>({ defaultValues: user ? { ...user, isPhysician: user.physicianIdentificationNumber && user.physicianIdentificationNumber.length } : {}, delayError: 700 })

  const { handleSubmit } = form;

  //Constants
  const genderValues = [
    { value: "M", label: "Masculino" },
    { value: "F", label: "Feminino" },
    { value: "O", label: "Outros" }
  ]

  let disableEmail = false;

  if (user && user.email) {
    disableEmail = true;
    form.setValue('email', user.email);
  }

  if (!states && !statesLoading && !statesError) {
    dispatch(getUserStates(null))
  }

  // React State
  const [isMedicCheckbox, setIsMedicCheckbox] = useState(user && user.physicianIdentificationNumber && user.physicianIdentificationNumber.length);
  let signPhotoData: any = null;

  // Functions
  const onSubmit = async (data: any) => {
    switch (userFormMode) {
      case UserFormModeEnum.ADMIN_REGISTER:
        return await adminRegisterSubmit(data);
      case UserFormModeEnum.ADMIN_EDIT:
        return await adminEditSubmit(data);
      case UserFormModeEnum.REGISTER:
        return await registerSubmit(data);

    }
  }

  function backToList() {
    if (UserFormModeEnum.REGISTER === userFormMode) {
      return navigate('/');
    }

    return dispatch(setAdminState(AdminStateEnum.USERS_LIST))
  }

  const onMedicCkecboxChange = (e: any) => {
    setIsMedicCheckbox(e.target.checked);
    if (!e.target.checked) {
      form.setValue('physicianIdentificationNumber', undefined);
      form.setValue('physicianState', undefined);
    }
  }

  if (userFormMode === UserFormModeEnum.REGISTER) {
    //get token from query params
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (!token) {
      // retornar para página de erro
      return <></>
    }

    const decodedToken = decodeJWT(token)
    if (!decodedToken) {
      // retornar para página de erro
      return <></>
    }

    if (!decodedToken.email) { }

    form.setValue('email', decodedToken.email);
    form.setValue('poolId', decodedToken.poolId);
    disableEmail = true;
  }

  if (userFormMode === UserFormModeEnum.ADMIN_REGISTER) {
    const poolParams = window.location.pathname.split('/')
    const poolId = poolParams[1]
    form.setValue('poolId', poolId)
  }

  //Submit Functions
  const adminRegisterSubmit = async (data: any) => {
    return dispatch(createUser(new CreateUser(data)))
      .then((result: any) => {
        if (!result || !result.payload || !result.payload.id) {
          if (result && result.error && result.error.message && result.error.message.includes("already registered")) {
            return toast.error("Erro ao criar o usuário! E-mail já está cadastrado.")
          }

          return toast.error("Erro ao criar o usuário!")
        }

        if (result && result.payload && result.payload.id && signPhotoData) {
          dispatch(updateSignature({ id: result.payload.id, signFormData: signPhotoData }))
            .then(resultSignPhoto => {
              if (!resultSignPhoto || !resultSignPhoto.payload || !resultSignPhoto.payload.id) {
                toast.error("Erro ao salvar a assinatura do usuário!")
              }
            })
        }

        form.reset();
        toast.success("Usuário criado com sucesso!")
        return backToList()
      })
  }

  const adminEditSubmit = async (data: any) => {
    return dispatch(updateUser(new UpdateUser({ ...data, id: user.id })))
      .then((result: any) => {
        if (!result || !result.payload || !result.payload.id) {
          if (result && result.error && result.error.message && result.error.message.includes("already registered")) {
            return toast.error("Erro ao editar o usuário! E-mail já está cadastrado.")
          }

          return toast.error("Erro ao editar o usuário!")
        }

        if (result && result.payload && result.payload.id && signPhotoData) {
          dispatch(updateSignature({ id: result.payload.id, signFormData: signPhotoData }))
            .then(resultSignPhoto => {
              if (!resultSignPhoto || !resultSignPhoto.payload || !resultSignPhoto.payload.id) {
                toast.error("Erro ao salvar a assinatura do usuário!")
              }
            })
        }

        toast.success("Usuário editado com sucesso!")
        return backToList()
      })
  }

  async function registerSubmit(data: any) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    data.isPasswordPermanent = true;

    if (!token) {
      return toast.error("Erro ao criar o usuário! Token inválido.")
    }

    return dispatch(createExternalUser({ params: new CreateUser(data), token }))
      .then((result: any) => {
        if (!result || !result.payload || !result.payload.id) {
          if (result && result.error && result.error.message && result.error.message.includes("already registered")) {
            return toast.error("Erro ao criar o usuário! E-mail já está cadastrado.")
          }

          return toast.error("Erro ao criar o usuário!")
        }

        form.reset();
        toast.success("Usuário criado com sucesso!")
        const decodedToken = decodeJWT(token)

        if (decodedToken.redirect_uri) {
          return window.location.replace(decodedToken.redirect_uri + "?user_id=" + result.payload.id + "&email=" + decodedToken.email);
        }

        return navigate('/');
      })
  }

  function updateSignUser(signFormData: any) {
    dispatch(updateSignature({ id: user.id, signFormData }))
      .then(resultSignPhoto => {
        if (!resultSignPhoto || !resultSignPhoto.payload || !resultSignPhoto.payload.id) {
          return toast.error("Erro ao salvar a assinatura do usuário!")
        }

        return toast.success("Assinatura salva com sucesso!")
      })
  }

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <Row>
              {
                userFormMode === UserFormModeEnum.REGISTER || userFormMode === UserFormModeEnum.ADMIN_REGISTER ? <></> :
                  <Col md={2}>
                    <label>Foto</label>
                    <hr className={style.line} />
                    <div className="d-flex justify-content-center align-items-center">
                      <ProfilePhoto id={"user-1"} currentProfilePhotoLink={user.photoLink} onProfilePhotoChange={function (profilePhotoData: FormData): void {
                        dispatch(updateUserPhoto({ user: user.id, picture: profilePhotoData }))
                          .then(() => {
                            toast.success("Foto atualizada com sucesso!")
                          })
                      }} />
                    </div>
                  </Col>
              }
              <Col md={6}>
                <Row>
                  <Col>
                    {UserFormModeEnum.REGISTER === userFormMode || UserFormModeEnum.ADMIN_REGISTER === userFormMode ? <>
                      <label>Novo usuário</label>
                    </> : <>
                      <label>Informações</label>
                    </>}
                    <hr className={style.line} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Input inputName={"email"} label={"E-mail"} form={form} validators={{ validate: validateEmail }} disabled={disableEmail} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Input inputName={"recoveryEmail"} label={"E-mail de recuperação"} form={form} validators={{ validate: validateRecoveryEmail(form) }} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Input inputName={"name"} label={"Nome"} form={form} validators={{ required: true }} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input inputName={"identificationNumber"} label={"CPF"} form={form} mask="999.999.999-99" validators={{ validate: validateCPF }}></Input>
                  </Col>
                  <Col md={6}>
                    <Input inputName={"phone"} label={"Telefone"} mask="+99 (99) 99999-9999" form={form} validators={{ validate: validatePhone(12) }}></Input>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Select inputName={"gender"} label={"Gênero"} form={form} values={genderValues}></Select>
                  </Col>
                  <Col md={6}>
                    <InputDate inputName={"birthdate"} label={"Data de nascimento"} form={form} />
                  </Col>
                </Row>
                <Row>
                  {
                    userFormMode === UserFormModeEnum.REGISTER || userFormMode === UserFormModeEnum.ADMIN_REGISTER ?
                      <Col md={6}>
                        <Input inputName={"password"} label={"Senha"} form={form} type="password" validators={{ validate: validatePassword }}></Input>
                      </Col> : <></>
                  }
                  <Col md={6}>
                    <Input inputName={"internalCode"} label={"Código Interno"} form={form}></Input>
                  </Col>
                </Row>
                {
                  userFormMode === UserFormModeEnum.ADMIN_REGISTER ?
                    <Row>
                      <Col md={12}>
                        <Checkbox inputName={"isPasswordPermanent"} label={"Senha Permanente"} form={form} />
                      </Col>
                    </Row> : <></>
                }
                {
                  userFormMode === UserFormModeEnum.ADMIN_EDIT ? <Row style={{ marginTop: 16 }}>
                    <Col>
                      <label>Administração</label>
                      <hr className={style.line} />
                    </Col>
                  </Row> : <></>
                }
                {
                  userFormMode === UserFormModeEnum.ADMIN_EDIT ?
                    <>
                      <Row>
                        <AdminUpdatePassword id={user.id} />
                        <AdminApplications id={user.id} />
                      </Row>
                      <Row>
                        <AdminLogs id={user.id} />
                        {
                          userFormMode === UserFormModeEnum.ADMIN_EDIT && user.hasMFAEnabled ?
                            <Col sm={6}>
                              <AdminRemoveMFA id={user.id} />
                            </Col>
                            : <></>
                        }
                      </Row>
                      {userFormMode === UserFormModeEnum.ADMIN_EDIT && user && !user.isEmailVerified ? <Row>
                        <Col sm={12}>
                          <Button text={"Marcar e-mail como verificado"} color={ButtonColors.flat_primary} onClick={() => { setEmailAsVerified() }} smallButton={true} />
                        </Col>
                      </Row> : <></>}
                    </>
                    : <></>
                }
              </Col>
              <Col md={userFormMode === UserFormModeEnum.REGISTER || userFormMode === UserFormModeEnum.ADMIN_REGISTER ? 6 : 4}>
                <Row>
                  <Col sm={12}>
                    <label>Área médica</label>
                    <hr className={style.line} />
                    <Row>
                      <Col md={12}>
                        <Checkbox inputName={"isPhysician"} label={"Usuário Médico"} form={form} onChange={onMedicCkecboxChange} />
                      </Col>
                    </Row>
                    {
                      isMedicCheckbox ?
                        <>
                          <Row style={{ paddingRight: '2em' }}>
                            <Col md={6}>
                              <Input inputName={"physicianIdentificationNumber"} label={"CRM"} form={form} validators={{ validate: validateCRM }} />
                            </Col>
                            <Col md={6}>
                              <StateSelect inputName={"physicianState"} label={"Estado"} form={form} values={states} validators={{ validate: validateCountryState(form) }}></StateSelect>
                            </Col>
                          </Row>
                          {userFormMode !== UserFormModeEnum.REGISTER ? <Row>
                            <Col sm={12}>
                              <label>Assinatura 420x120px</label>
                              <hr className={style.line} />
                              <Row>
                                <Col sm={12}>
                                  <SignPhoto currentSignLink={user && user.signLink ? user.signLink : ""}
                                    onSignChange={updateSignUser}
                                  />
                                </Col>
                              </Row>
                              <Row style={{ paddingLeft: '1em' }}>
                                <a style={{ fontWeight: 'bold' }} href={imgSignature} download="mobilemed-assinatura">
                                  Download do modelo de assinatura
                                </a>
                              </Row>
                            </Col>
                          </Row> : <> </>}
                        </> : <></>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex justify-content-end mt-3">
                  <div className="mx-2">
                    <Button text={"Cancelar"} disabled={userSlice.registerLoading || userSlice.updateLoading} onClick={backToList} color={ButtonColors.warn} />
                  </div>
                  {
                    userFormMode === UserFormModeEnum.EDIT || userFormMode === UserFormModeEnum.ADMIN_EDIT ?
                      <Button text={"Salvar"} type={ButtonTypes.submit} color={ButtonColors.primary} isLoading={userSlice.updateLoading} /> :
                      <Button text={"Registrar"} type={ButtonTypes.submit} color={ButtonColors.primary} isLoading={userSlice.registerLoading} />
                  }
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /* width: 80%;
	margin: auto; */
`;

export default UserForm;
