import { AsyncState } from "./AsyncState";

export enum AdminStateEnum {
   USERS_LIST = 'USERS_LIST',
   REGISTER_USER = 'REGISTER_USER',
   UPDATE_USER = 'UPDATE_USER',
}

export class AdminState extends AsyncState {
    state: AdminStateEnum = AdminStateEnum.USERS_LIST;
    registerLoading: boolean = false;
    registerError: string | null = null;
    registerValue: any = null;
}