import style from "../../pages/FormLogin/FormLogin.module.scss";
import { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { getLogUsers, getUserLogs } from "../../redux/user/user-thunks";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import LoadingWrapper from "../Loading";
import Accordion from 'react-bootstrap/Accordion';

import moment from "moment";

function Logs({ userId }: { userId: any }) {
  const dispatch = useAppDispatch();
  const [logs, setLogs] = useState<any>([]);

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const data = useSelector((state: any) => state.userReducer.logs);
  const loading = useSelector((state: any) => state.userReducer.logsLoading);
  const users: any = useSelector((state: any) => state.userReducer.logUsers);
  const [logUsers, setLogUsers] = useState<any>([])

  useEffect(() => {
    getLogs(userId)
  }, [page]);

  function getDate(item: any) {
    let dataMoment = moment(item.timestamp).format("DD/MM/YYYY HH:mm")
    return dataMoment
  }

  async function getLogs(userId: any) {
    dispatch(getUserLogs({ user: userId, page: page }))
      .then((res: any) => {
        getEmails(res.payload.body)
        logsData(res.payload.body)
        setTotal(res.payload.total)
        return res.payload.body
      })
  }

  async function getEmails(data: any) {
    let arrayIds: any = []
    let readData = [...data]

    readData.map((item: any) => {
      arrayIds.push(`&id=${item?.userId}`)
    })

    dispatch(getLogUsers({
      data: {},
      page: '1',
      limit: '10',
      filterParams: `${arrayIds.join("")}`
    })).then((res: any) => {
      setLogUsers(res.payload.users)
    })
  }

  function getEmail(userId: any) {
    let email = ""
    logUsers.map((item: any) => {
      if (userId === item.id) {
        email = item.email
      }
    })
    return email
  }

  async function logsData(data: any) {
    let readData = [...data]
    let logsArray: any = []
    let logsSecurity: any = []
    let newLogs: any = []

    readData.map((item: any) => {
      let dataCheck = item.data
      if (Object.keys(dataCheck).length > 0) {
        logsArray.push(item)
      }
      else {
        logsSecurity.push(item)
      }
    })

    for (let i = 0; i < logsArray.length - 1; i++) {
      var logBefore: any = []
      var logAfter: any = []
      var logsAfter: any = []
      var logsBefore: any = []

      const item1 = JSON.parse(JSON.stringify(logsArray[i]))
      const item2 = JSON.parse(JSON.stringify(logsArray[i + 1]));
      const itemData1 = item1?.data
      const itemData2 = item2?.data

      var keys = Object.keys(itemData1);
      for (var j = 0; j < keys.length; j++) {
        var key = keys[j];
        if (itemData1[key] !== itemData2[key]) {

          logBefore[key] = itemData2[key]
          logAfter[key] = itemData1[key]

          item1.key = key
          let itemCurrent = JSON.parse(JSON.stringify(item1))

          let infoBefore = itemData2[key] + ' '
          let infoAfter = itemData1[key] + ' '

          if (key === 'photoLink' || key === 'photoPath') {
            infoBefore = 'Foto do usuário'
            infoAfter = 'Foto do usuário'
          }

          itemCurrent.before = infoBefore
          itemCurrent.after = infoAfter

        }
      }
      logsBefore.push(logBefore)
      logsAfter.push(logAfter)

      newLogs.push({
        timestamp: item1.timestamp,
        email: item1.userId,
        action: item1.action,
        ip: item1.ip,
        data: item1.data,
        before: logBefore,
        after: logAfter
      })
    }

    for (let i = 0; i < logsSecurity.length - 1; i++) {
      newLogs.push({
        timestamp: logsSecurity[i].timestamp,
        email: logsSecurity[i].userId,
        action: logsSecurity[i].action,
        ip: logsSecurity[i].ip,
        data: logsSecurity[i].data,
        before: {},
        after: {}
      })
    }
    const Moment = require('moment')
    const sortedArray = newLogs.sort((a: { timestamp: any; }, b: { timestamp: any; }) =>
      new Moment(b.timestamp) - new Moment(a.timestamp))
    newLogs.map((item: any) => {
      item.timestamp = getDate(item)
    })
    setLogs(newLogs)
  }

  function updateKey(key: any) {
    switch (key) {
      case 'email':
        return 'e-mail';
      case 'recoveryEmail':
        return 'e-mail de recuperação';
      case 'name':
        return 'nome';
      case 'phone':
        return 'telefone';
      case 'gender':
        return 'sexo';
      case 'enableSafeId':
        return 'safeID:';
      case 'identificationNumber':
        return 'CPF';
      case 'hasMFAEnabled':
        return 'MFA habilitado';
      case 'signLink':
        return 'link de assinatura';
      case 'physicianIdentificationNumber':
        return 'CRM';
      case 'physicianState':
        return 'estado';
      case 'internalCode':
        return 'código interno';
      case 'birthdate':
        return 'Data de nascimento';

      default:
        return key;
    }
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    await submitForm(event.target);
  }

  function submitForm(event: any) {
    return event;
  }

  function turnArray(obj: any) {
    let keys = Object.keys(obj)
    let values = Object.values(obj)
    let array: any = []
    for (var i = 0; i < keys.length; i++) {
      let string = `${keys[i]} : ${values[i]}; \n\r `
      array.push(
        string
      )
    }
    return array
  }

  return (
    <>
      <section>
        <LoadingWrapper isLoading={!data || loading}>
          <Row>
            <Table responsive id="logs" style={{ marginBottom: '0' }}>
              <thead>
                <tr
                  style={{ textAlign: "center", borderWidth: "0px 0px 2px 0px" }}
                >
                  <th
                    style={{ textAlign: 'left', width: '10%' }}
                  >
                    <b>Data e Hora</b>
                  </th>
                  <th style={{ width: '15%' }}>
                    <b>E-mail de usuário</b>
                  </th>
                  <th style={{ width: '15%' }}>
                    <b>Alteração</b>
                  </th>
                  <th style={{ width: '10%' }}>
                    <b>IP</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {logs.map((item: any, index: any) => <>
                  <tr>
                    {
                      item?.data ?
                        <td colSpan={4}>
                          <Accordion style={{ width: '100%' }}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                                  <Col sm={1}
                                    style={{
                                      textAlign: 'left',
                                    }}>
                                    {item?.timestamp}
                                  </Col>
                                  <Col sm={3}
                                    title={item?.data?.email}>
                                    {getEmail(item?.email)}
                                  </Col>
                                  <Col sm={2}
                                    title={`${item?.action} ${item?.key ? updateKey(item?.key) : ''} `}
                                    className={style.upperCase}>
                                    {`${item?.action === 'create' ? 'Criação de usuário' : item?.action} `}
                                  </Col>
                                  <Col sm={1} >{item?.ip}</Col>
                                </Row>
                              </Accordion.Header>
                              {Object.keys(item?.data).length > 0 ?
                                <Accordion.Body>
                                  <Row style={{ maxWidth: '100%', display: 'flex', justifyContent: 'space-around' }}>
                                    <Col sm={5} >
                                      <Row style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Antes</Row>
                                      <Row style={{ display: 'flex', justifyContent: 'center', overflow: 'auto', width: '115%' }}>{
                                        turnArray(item?.before)
                                      }</Row>
                                    </Col>
                                    <Col sm={1}></Col>
                                    <Col sm={5}>
                                      <Row style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Depois</Row>
                                      <Row style={{ display: 'flex', justifyContent: 'center', overflow: 'auto', width: '115%' }}>{
                                        turnArray(item?.after)
                                      }</Row>
                                    </Col>
                                  </Row>
                                </Accordion.Body>
                                : <></>}
                            </Accordion.Item>
                          </Accordion>
                        </td>
                        : <></>
                    }
                  </tr>
                </>
                )}
                <tr>
                  <td colSpan={6}>
                    <PaginationControl
                      page={page}
                      between={4}
                      total={total}
                      limit={10}
                      changePage={(page) => {
                        setPage(page);
                      }}
                      ellipsis={1}
                    />
                  </td>
                </tr>

              </tbody>
            </Table>
          </Row>
        </LoadingWrapper>
      </section>
    </>
  );
}

export default Logs;
