import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Portal } from "@mui/base";
import { useAppDispatch } from "../../redux/store";
import { adminRemoveMFA } from "../../redux/admin/admin-thunks";
import { toast } from "react-toastify";
import Button, { ButtonColors, ButtonTypes } from "../Button";
import { useSelector } from "react-redux";

type AdminRemoveMFAProps = {
  id: string;
};
export default function AdminRemoveMFA({ id }: AdminRemoveMFAProps) {
  const [showModal, setShowModal] = useState(false);
  const removeMFALoading: any = useSelector((state: any) => state.adminReducer.removeMFALoading);

  const dispatch = useAppDispatch();

  function onSubmit() {
    dispatch(adminRemoveMFA(id)).then((resp: any) => {
      if(resp && resp.error) {
        return toast.error("Erro ao remover o MFA");
      }
      
      setShowModal(false);
      toast.success("MFA removido com sucesso");

      return;
    })
  }

  return (
    <>
      <Button text="Remover autenticação de dois fatores" smallButton={true} color={ButtonColors.flat_warn} type={ButtonTypes.button} onClick={() => setShowModal(true)} />
      <Portal>
          <Modal show={showModal}>
            <Modal.Header>
              <Modal.Title>Remover autenticação de dois fatores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Deseja realmente REMOVER a autenticação de dois fatores do usuário?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button text={"Cancelar"} color={ButtonColors.warn} onClick={() => setShowModal(false)} disabled={removeMFALoading} />
              <Button text={"Remover"} color={ButtonColors.primary} onClick={() => onSubmit()} isLoading={removeMFALoading} />
            </Modal.Footer>
          </Modal>
      </Portal>
    </>
  );
}
