export function validatePhone(min: number): Function {
  return (value: string) => {
    value = value.split(' ').join('').split('-').join('').split('(').join('').split(')').join('').split('+').join('');
    if (!value || value.length === 0 ) {
      return true;
    }

    return value.length >= min;
  }
}

export function validateCPF(value: any) {
  var cpfNumber = value || '';

  let validateCPF = cpfNumber.replace(/[-. ]/g, '')

  if(validateCPF.length === 0) {
    return true;
  }

  if (validateCPF.length === 11) {
    var soma;
    var resto;
    soma = 0;
    if (validateCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) soma = soma + parseInt(validateCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(validateCPF.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) soma = soma + parseInt(validateCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(validateCPF.substring(10, 11))) return false;

    return true;
  }

  return false;
}

export function validateEmail(value: any) {
  if(value.length === 0) {
    return false;
  }

  var email = value;
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function validateRecoveryEmail(form: any): Function {
  return (value: string) => {
    const values = form.getValues()
    let userEmail = values.email

    if(!value || value.length === 0){
      return true
    }

    let recoveryEmail = value;
    let re = /\S+@\S+\.\S+/;

    if(recoveryEmail === userEmail){
      return false
    }

    return re.test(recoveryEmail);
  }
}

export function validatePassword(value: any) {
  if(value.length === 0) {
    return false;
  }

  var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/;
  return re.test(value);
}

export function validateCountryState(form: any): Function {
  return (value: string) => {
    const values = form.getValues()
    if(values.physicianIdentificationNumber.length === 0) {
      return true;
    }

    if(value.length === 0) {
      return false;
    }
    
    return true;
  }
}

export function validateCRM(value: string) {
  if(!value || value.length === 0) {
    return false;
  }

  const valueInNumber = Number(value);

  if(Number.isNaN(valueInNumber)) {
    return false;
  }

  if(valueInNumber === 0) {
    return false;
  }

  return true;
}

export function validateRepeatPassword(form: any): Function {
  return (value: string) => {
    const values = form.getValues()
    if(values.password !== value) {
      return false;
    }

    return true;
  }
}