import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Portal } from "@mui/base";
import { useAppDispatch } from "../../redux/store";
import { toast } from "react-toastify";
import Button, { ButtonColors, ButtonTypes } from "../Button";
import { useSelector } from "react-redux";
import { getCurrentUser, removeMFACode } from "../../redux/user/user-thunks";


export default function RemoveMFA() {
  const [showModal, setShowModal] = useState(false);
  const removeMFALoading: any = useSelector((state: any) => state.userReducer.removeMFACodeLoading);

  const dispatch = useAppDispatch();

  function onSubmit() {
      dispatch(removeMFACode()).then((resp: any) => {
        if (resp && resp.error) {
          return toast.error("Ocorreu um erro ao remover o autenticador")
        }

        dispatch(getCurrentUser())

        setShowModal(false)
        return toast.success("Autenticador removido com sucesso")
      });
  }

  return (
    <>
      <Button text="Remover" smallButton={true} color={ButtonColors.flat_warn} type={ButtonTypes.button} onClick={() => setShowModal(true)} />
      <Portal>
          <Modal show={showModal}>
            <Modal.Header>
              <Modal.Title>Remover autenticação de dois fatores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Deseja realmente REMOVER a autenticação de dois fatores?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button text={"Cancelar"} color={ButtonColors.warn} onClick={() => setShowModal(false)} disabled={removeMFALoading} />
              <Button text={"Remover"} color={ButtonColors.primary} onClick={() => onSubmit()} isLoading={removeMFALoading} />
            </Modal.Footer>
          </Modal>
      </Portal>
    </>
  );
}
