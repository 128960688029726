import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import styleButton from "../../../../components/Button/Button.module.scss";
import { Portal } from "@mui/base";
import { toast } from "react-toastify";
import Button, { ButtonColors, ButtonTypes } from "../../components/Button";
import Logs from "../Logs";
import { ModalDefault } from "../../components/ModalDefault/modal";
import Applications from "../Applications/Applications";

type AdminAppsProps = {
    id: string;
};
export default function AdminApplications({ id }: AdminAppsProps) {
    const useModal = () => {
        const [isShown, setIsShown] = useState<boolean>(false);
        const toggle = () => {
            setIsShown(!isShown)
        };
        return {
            isShown,
            toggle,
        };
    };

    const { isShown, toggle } = useModal();

    return (
        <>
            <Col sm={4}>
                <Button text="Sessões do usuário" smallButton={true} color={ButtonColors.flat_primary} type={ButtonTypes.button} onClick={toggle}
                />
            </Col>
            <ModalDefault
                isShown={isShown}
                hide={toggle}
                headerText='Sessões do usuário'
                modalContent={
                    <Applications userId={id} />
                }
            />
        </>
    );
}
