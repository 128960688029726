import { Card, Container } from "react-bootstrap";
import mobilemedImg from '../../assets/img/mobilemed-icon.svg'
import { useEffect, useState } from "react";
import { UserService } from "../../services/UsersService";
import { BiSolidError, BiSolidCheckCircle } from "react-icons/bi";


export function VerifyEmail() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(false);
  const [success, setSuccess] = useState<any>(false);

  function redirectToBaseUrl(poolId?: string) {
    setTimeout(() => {
      if(poolId) {
        window.location.href = `/${poolId}/mobilemed`;
        return;
      }

      window.location.href = "/";
    }, 2000);
  }

  useEffect(() => {
    //get query string
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const userId = urlParams.get('userId');
    const poolId = urlParams.get('poolId');

    if((!code || !userId) && !loading && !success && !error) {
      setError(true);
      redirectToBaseUrl(poolId || undefined)
      return;
    }
    
    if(!loading && !success && !error) {
      setLoading(true);
      UserService.sendCodeEmail(userId, code!)
        .then(() => {
          setSuccess(true);
          setLoading(false);

          redirectToBaseUrl(poolId || undefined)
        }).catch((err) => {
          setError(true);
          setLoading(false);
          redirectToBaseUrl(poolId || undefined)
        })
    }
  })


  var iconContent = <></>;
  var textContent = "";

  if(loading) {
    iconContent = <div className="spinner-border spinner-border-sm" role="status" style={{ fontSize: '16px', color: "#005ca8" }} />
    textContent = "Verificando E-mail"
  } else if(error) {
    iconContent = <div className="d-flex align-items-end justify-content-center "><BiSolidError style={{ fontSize: '16px', color: "#EF4F4F" }} /></div>
    textContent = "Ocorreu um erro ao verificar o e-mail."
  } else if(success) {
    iconContent = <div className="d-flex align-items-end justify-content-center "><BiSolidCheckCircle style={{ fontSize: '16px', color: "#1dd1a1" }} /></div>
    textContent = "E-mail verificado com sucesso!"
  }

  return  <Container>
  <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
    <Card style={{ padding: 24 }}>

      <Card.Title style={{ textAlign: "center" }}>{textContent}</Card.Title>
      <div className="text-center mt-4">
        <div className="d-flex align-items-end justify-content-center ">
          <img src={mobilemedImg} alt="MobileMed" style={{ width: '100px' }} />
          {iconContent}
        </div>
      </div>
    </Card>
  </div>
</Container>
}