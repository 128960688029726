import axios from "axios";
import { API_BASE_URL } from "../shared/Config";

export const EstadosService = {
    getStates: async function (id?: any) {

        return axios({
            url: `${API_BASE_URL}/region/${id}`,
            method: "get"
        });
    },

}