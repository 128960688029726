import { IconContext } from "react-icons";
import { FaMobileAlt } from "react-icons/fa";

function RegisteredAuthenticator() {
    return (
        <div style={{ flexDirection: "row", display: "flex", alignItems: "center", marginLeft:'1em' }}>
            <div>
                <IconContext.Provider value={{ color: "black", style: { marginRight: '0.6em', height: '3em', fontSize: "2em" } }}>
                    <span>
                        <FaMobileAlt />
                    </span>
                </IconContext.Provider>
            </div>
            <div>
                <p><strong>Aplicativo Autenticador</strong></p>
                <p>Dispositivo móvel cadastrado.</p>
                <p>Ao entrar na tela de login será exigido o código do aplicativo.</p>
            </div>
        </div>
    );
}

export default RegisteredAuthenticator;