import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 700;
	width: inherit;
	outline: 0;
`;

export const Backdrop = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 500;
`;

export const StyledModal = styled.div`
	transition: width 2s, height 4s;
	transition-timing-function: ease-in-out;
	z-index: 100;
	background: white;
	position: relative;
	margin: auto;
	border-radius: 8px;
	/* width: max-content; */
	width: 75em;
	overflow: auto;

	@media (min-width: 1600px){
		width: 90em;
	}
	
  @media(max-width: 1200px){
		width: 65em;
  }

  @media (max-width:1085px){
	  width: 60em
  }

  @media (max-width:980px){
	  width:50em
  }

  @media (max-width:815px){
	  width:40em
  }

  @media (max-width:670px){
	  width:25em
  }

  @media (max-width:400px){
	  width:15em
  }
`;

export const Header = styled.div`
	border-radius: 8px 8px 0 0;
	display: flex;
	justify-content: space-between;
	padding: 1rem 1rem 0;
`;

export const HeaderText = styled.div`
	color: #fff;
	align-self: center;
	color: lightgray;
`;

export const CloseButton = styled.button`
	font-size: 0.8rem;
	border: none;
	border-radius: 3px;
	margin-left: 0.5rem;
	background: none;
	:hover {
		cursor: pointer;
	}
`;

export const Content = styled.div`
	/* padding: 10px; */
	padding: 8px 10px;
	// max-height: 30rem;
	max-height: 40rem;
	overflow-x: hidden;
	overflow-y: auto;
`;