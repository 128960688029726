import style from "./warning-card.module.scss"

function WarningCard(errorMessage: any) {

  let message = ""
  if (errorMessage.errorMessage === 'errorCPF') {
    message = "CPF inválido"
  }
  
  if (errorMessage.errorMessage === 'errorPhone') {
    message = "Telefone inválido"
  }

  if (errorMessage.errorMessage === 'errorEmail') {
    message = "E-mail inválido"
  }

  if (errorMessage.errorMessage === 'errorRecoveryEmail') {
    message = "E-mail de recuperação inválido"
  }


  if (errorMessage.errorMessage === 'errorPassword') {
    message = "Senha inválida"
  }

  if (errorMessage.errorMessage === 'errorName') {
    message = "Nome inválido"
  }

  if (errorMessage.errorMessage === 'errorIdentificationNumber') {
    message = "CPF inválido"
  }

  if (errorMessage.errorMessage === 'errorPhysicianState') {
    message = "Estado é obrigatório"
  }

  if(errorMessage.errorMessage === 'errorPhysicianIdentificationNumber') {
    message = "CRM é obrigatório"
  }

  if(errorMessage.errorMessage === 'errorRepeatPassword') {
    message = "A senha devem coincidir"
  }

  if(errorMessage.errorMessage === 'errorMFAcode') {
    message = "O código deve conter 6 dígitos"
  }

  return (
    <>
      <div style={{ color: '#f94646' }} className={style.card}>
        <span dangerouslySetInnerHTML={{ "__html": message }} />
      </div>
    </>)
}

export default WarningCard