import style from "../../pages/home/App.module.scss";
import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import ModalWrapper from "../ModalWrapper";
import imgSignature from "../../assets/img/foto-usuario.png"
import { FaCamera } from "react-icons/fa";

type ProfilePhotoProps = {
  id: string
  currentProfilePhotoLink?: string;
  onProfilePhotoChange: (profilePhotoData: FormData) => void;
}

export function ProfilePhoto({ id, currentProfilePhotoLink, onProfilePhotoChange }: ProfilePhotoProps) {
  const [showModal, setShowModal] = useState(false);

  const [photoUser, setImgUser] = useState(currentProfilePhotoLink ? currentProfilePhotoLink : imgSignature);
  const [croppedImg, setCroppedImg] = useState(currentProfilePhotoLink ? currentProfilePhotoLink : imgSignature);

  const handleFileSelect = async (e: any) => {
    try {
      e.preventDefault();
      let files;

      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }

      const reader = new FileReader();

      if (!reader) return;

      reader.onload = () => {
        let result = reader.result ? reader.result : "";
        setImgUser(result.toString());
        e.target.value = null;
        setShowModal(true);
      };

      reader.readAsDataURL(files[0]);
    } catch (error) {
    }
  };

  useEffect(() => {
    if ((!croppedImg || !photoUser) && currentProfilePhotoLink) {
      setImgUser(currentProfilePhotoLink)
      setCroppedImg(currentProfilePhotoLink)
    }
  }, []);

  useEffect(() => {
  }, [showModal]);

  useEffect(() => {
    modalImgUser()
  }, [croppedImg]);

  async function modalImgUser() {
    var updatedPhoto = croppedImg.match("blob")
    if (!!updatedPhoto) {
      var formData = new FormData();
      const response = await fetch(croppedImg);
      const blob = await response.blob();
      formData.append("file", blob);

      onProfilePhotoChange(formData);
    }
  }

  return (
    <>
      <div>
        <div className={style.hoverProfileUser}>
          <div className={style.iconLogoHover}>
            <FaCamera />
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileSelect} id={id} />
          <label htmlFor={id}>
            <Image
              id="userImg"
              src={croppedImg}
              className={style.hoverImgUser} 
              />
          </label>
        </div>
      </div>

      <ModalWrapper
        showModal={showModal}
        imgURL={photoUser}
        imageRatio={1}
        onSaveHandler={setCroppedImg}
        onModalClose={() => {
          setShowModal(false);
        }}
      />
    </>
  )
}