import { useEffect } from "react";
import { AuthService } from "../../services/AuthService";
import {
  setAuthUser,
} from "../../redux/authentication/auth-slice";
import { getCurrentUser, getUserStates } from "../../redux/user/user-thunks";
import { useAppDispatch } from "../../redux/store";
import { User } from "oidc-react";

export default function AuthProvider({ ...props }) {
  const dispatch = useAppDispatch();

  const removeCodeFromUrl = () => {
    let newUrl = window.location.href.replace(/[?&]code=[^&]+/, "");
    newUrl = newUrl.replace(/[?&]state=[^&]+/, "");

    window.history.replaceState({}, document.title, newUrl);
  };

  async function signinCallback(user: User | null = null) {
    const userManager = await AuthService.getUserManager();
    try {
      AuthService.clearLocalStorage();
      userManager.startSilentRenew();
      await userManager.signinSilent();
  
      user = await userManager.getUser();
  
      removeCodeFromUrl();
  
      dispatch(setAuthUser(user))
  
      dispatch(getCurrentUser());
  
      dispatch(getUserStates(null));
    } catch (error) {
      userManager.signinRedirect();
    }
  }

  useEffect(() => {
    const handleSignIn = async () => {
      try {
        const userManager = await AuthService.getUserManager();

        let user = await userManager.getUser();

        if (user) {
          signinCallback(user)

        } else {
          removeCodeFromUrl();
          userManager.signinRedirect();
        }
      } catch (error) {
      }
    };

    const handleCallback = async () => {
      try {
        const userManager = await AuthService.getUserManager();
        const user = await userManager.signinRedirectCallback();
        removeCodeFromUrl();

        signinCallback(user);
      } catch (error) { }
    };

    if (
      window.location.href.includes("code") &&
      window.location.href.includes("state")
    ) {
      handleCallback();
    } else {
      handleSignIn();
    }


  }, [dispatch]);

  return <>{props.children}</>;
}
