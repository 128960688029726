type LoadingProps = {
  isLoading: boolean;
  children: React.ReactNode;
}

export default function LoadingWrapper({ children, isLoading }: LoadingProps) {
  if (!isLoading) {
    return <>{children}</>
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}>
      <div style={{color: "#005ca8"}}>
        <div className="spinner-border spinner-border-md" role="status" />
      </div>
    </div>
  );
}
