import { UserManager } from "oidc-react";
import { WebStorageStateStore } from "oidc-client-ts";
import { CLIENT_ID, OIDC_AUTHORITY, REDIRECT_URI, OIDC_DEFAULT_USER_POOL_ID, OIDC_DEFAULT_TENANT } from "../shared/Config";
import axios from 'axios';
import { API_BASE_URL } from '../shared/Config';
import UserClientId from "../shared/UserClientId";

const parameters = window.location.pathname.split('/')
let sessionPoolId = sessionStorage.getItem('currentPoolId');
let sessionTenant = sessionStorage.getItem('currentTenant');

if (!sessionPoolId || (parameters[1].length > 0 && sessionPoolId !== parameters[1])) {
    sessionPoolId = parameters[1]
    sessionTenant = parameters[2]
}

let sessionClient = sessionStorage.getItem(`currentClientId_${sessionPoolId}`)

export const AuthService = {
    getUserManager: async () => {
        const poolParams = sessionPoolId
        const poolTenant = sessionTenant
        const client = sessionClient
        let clientId: any;
        let clientName: any;
        let userSettings: any;

        userSettings = await getClient().then((res) => {
            return ({
                authority: poolParams ? `${OIDC_AUTHORITY}/${poolParams}/${poolTenant}` : `${OIDC_AUTHORITY}/${OIDC_DEFAULT_USER_POOL_ID}/${OIDC_DEFAULT_TENANT}` || "",
                client_id: client || res || CLIENT_ID,
                redirect_uri: poolParams ? `${REDIRECT_URI}/${poolParams}/${poolTenant}` : `${REDIRECT_URI}/${OIDC_DEFAULT_USER_POOL_ID}/${OIDC_DEFAULT_TENANT}` || "",
                response_type: 'code',
                automaticSilentRenew: true,
                userStore: new WebStorageStateStore({ store: window.localStorage }),
                stateStore: new WebStorageStateStore({ store: window.localStorage }),
            });
        }).catch((err) => {
            return ({
                authority: `${OIDC_AUTHORITY}/${OIDC_DEFAULT_USER_POOL_ID}/${OIDC_DEFAULT_TENANT}` || "",
                client_id: CLIENT_ID || "",
                redirect_uri: `${REDIRECT_URI}/${OIDC_DEFAULT_USER_POOL_ID}/${OIDC_DEFAULT_TENANT}` || "",
                response_type: 'code',
                automaticSilentRenew: true,
                userStore: new WebStorageStateStore({ store: window.localStorage }),
                stateStore: new WebStorageStateStore({ store: window.localStorage }),
            });
        })

        function getClient() {
            return new Promise<string>(async (resolve, reject) => {
                clientId = await UserClientId()
                clientName = clientId.clientName
                if (clientName) {
                    return resolve(clientName)
                }
                else return resolve(clientId)
            })
        }

        return new UserManager(userSettings)

    },

    getClientId: async function (poolId: any) {
        return axios({
            url: `${API_BASE_URL}/pool/${poolId}/clientId`,
            method: "get",
        })
    },

    clearLocalStorage: function() {
        for (let i = window.localStorage.length - 1; i >= 0; i--) {
            const key = window.localStorage.key(i)

            if (key && !key.includes('oidc.user')) {
                localStorage.removeItem(key)
            }
        }
    },
}