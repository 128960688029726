import { createSlice } from "@reduxjs/toolkit";
import { AdminState } from "../classes/AdminState";
import { adminRemoveMFA, getEditUser } from "./admin-thunks";

const initialState = new AdminState();

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminState: (state, action) => {
      return { ...state, state: action.payload };
    },
    setAdminUserEdit: (state, action) => {
      return { ...state, userEdit: action.payload };
    },
    clearEditUser: (state, action) => {
      return { ...state, userEdit: null, loading: false, error: null };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditUser.fulfilled, (state, action) => {
        return { ...state, userEdit: action.payload };
      })
      .addCase(getEditUser.pending, (state, action) => {
        return { ...state, userEdit: null, loading: true, error: null };
      })
      .addCase(getEditUser.rejected, (state, action) => {
        return { ...state, userEdit: null, loading: false, error: action.error.message };
      })
    builder
      .addCase(adminRemoveMFA.fulfilled, (state, action) => {
        return { ...state, removeMFA: action.payload, removeMFALoading: false, removeMFAerror: null };
      })
      .addCase(adminRemoveMFA.pending, (state, action) => {
        return { ...state, removeMFA: null, removeMFALoading: true, removeMFAerror: null };
      })
      .addCase(adminRemoveMFA.rejected, (state, action) => {
        return { ...state, removeMFA: null, removeMFALoading: false, removeMFAerror: action.error.message };
      })
  }
});

export const { setAdminState } = adminSlice.actions;
export default adminSlice.reducer;
