import style from "../../pages/home/App.module.scss";
import { Form } from "react-bootstrap";
import WarningCard from "../WarningCard";
import { useEffect } from "react";

type SelectValue = {
    stateCode: string;
    name: string;
}

type InputProps = {
    inputName: string;
    label: string;
    disabled?: boolean;
    validators?: any;
    form: any;
    values: SelectValue[];
};

export function StateSelect({ inputName, label, form, validators, disabled, values }: InputProps) {
    const { register, formState: { errors }} = form;
    const validatorsKeys = Object.keys(validators || {});

    return (
        <>
            {values &&
                <Form.Group className={`${label? "mt-4 mb-2" : ""}`} controlId="formGroupName" style={{ width: "100%" }}>
                    {label && <Form.Label>{label}</Form.Label>}

                    <select {...register(inputName, validators)} className={`${style.input} ${errors[inputName] ? style.fieldError : ''}`} style={{ width: "100%", height: '35.2px' }} title={inputName}>
                        <option key={'empty'} value={''}>{''}</option>
                        {values?.map((value) => {
                            return <option key={value.stateCode} value={value.stateCode}>{value.name}</option>
                        })}

                    </select>

                    {validatorsKeys.map((key) => {
                        if (errors[inputName] && errors[inputName].type === key) {
                            return <WarningCard key={key} errorMessage={`error${inputName.charAt(0).toUpperCase() + inputName.slice(1)}`}></WarningCard>
                        }

                        return <div key={key}></div>
                    })}
                </Form.Group>
            }
        </>
    );
}
