import { AuthService } from "../services/AuthService";
import { CLIENT_ID } from "./Config";

let clientSettings : any = {}
async function UserClientId() {
  const poolId = window.location.pathname.split('/')
  let clientId;
  if (poolId[1].length > 0 ){
    if(clientSettings[poolId[1]]){
      return clientSettings[poolId[1]]
    }
    clientId = await AuthService.getClientId(poolId[1])
    clientSettings[poolId[1]] = clientId?.data
    sessionStorage.setItem(`currentClientId_${poolId[1]}`, clientId?.data.clientName)
    return clientId?.data
  }
  else {
    clientId = CLIENT_ID
    return clientId
  }
}

export default UserClientId