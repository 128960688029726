import style from "../../pages/home/App.module.scss";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import { useForm } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Table } from "react-bootstrap";
import { FaUserEdit, FaUserTimes, FaUserCheck, FaSearch, } from "react-icons/fa";
import { FaFilterCircleXmark, } from "react-icons/fa6";
import { UserService } from "../../services/UsersService";

import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { getAdminUsers } from "../../redux/user/user-thunks";
import { Input } from "../Input/input";
import { Select } from "../Select/select";
import { StateSelect } from "../StateSelect/stateSelect";
import { setAdminState } from "../../redux/admin/admin-slice";
import { AdminStateEnum } from "../../redux/classes/AdminState";
import { closeUserList } from "../../redux/user/user-slice";
import { getEditUser } from "../../redux/admin/admin-thunks";
import { getUserPools } from "../../redux/pool/pool-thunks";
import Button, { ButtonColors, ButtonTypes } from "../Button";
import { toast } from "react-toastify";


function UsersList() {
    const poolParams= window.location.pathname.split('/')
    const poolId = poolParams[1]

    const states: any = useSelector((state: any) => state.userReducer.states.states);

    const form: any = useForm({ defaultValues: {}, delayError: 700 })

    const [filterParams, setFilterParams] = useState(`${`&poolId=${poolId}`}`)

    const pool = useSelector((state: any) => state.poolReducer);
    var poolMapped = [...[{ value: "", label: "Todos" }], ...pool.pools]


    async function onChangeFilter(data: any) {
        let filterParams = `${data.name ? `&name=${data.name}` : ''}${data.physicianIdentificationNumber ? `&physicianIdentificationNumber=${data.physicianIdentificationNumber}` : ''}${data.physicianState ? `&physicianState=${data.physicianState}` : ''}${data.disabled === 'inactive' ? `&disabled=${true}` : data.disabled === 'active' ? `&disabled=${false}` : ''}${data.email ? `&email=${data.email}` : ''}${data.phone ? `&phone=${data.phone}` : ''}${`&poolId=${poolId}`}`
        setFilterParams(filterParams)
        dispatch(getAdminUsers({ data: {}, page: "1", limit: '10', filterParams: filterParams }))
    }

    const status = [
        { value: "", label: "Todos" },
        { value: "active", label: "Ativo" },
        { value: "inactive", label: "Inativo" },
    ]

    const { getValues, reset } = form;

    const [page, setPage] = useState(1)

    const dispatch = useAppDispatch();
    const users: any = useSelector((state: any) => state.userReducer.adminUsers);
    const alreadyLoaded: any = useSelector((state: any) => state.userReducer.adminFirstLoad);

    if (!pool || (!pool.loading && !pool.pools.length)) {
        dispatch(getUserPools())
    }

    if (!alreadyLoaded) {
        dispatch(getAdminUsers({
            data: {},
            page: "1",
            limit: '10',
            filterParams: `${`&poolId=${poolId}`}`,
        }))
    }

    function toggleRegister() {
        dispatch(closeUserList({}))
        return dispatch(setAdminState(AdminStateEnum.REGISTER_USER))
    }

    const editUser = (id: any) => {
        dispatch(getEditUser({ id: id }))
            .then((result: any) => {
                dispatch(setAdminState(AdminStateEnum.UPDATE_USER))
            })
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        await onChangeFilter(getValues())
    }

    function getUserPhone(phone: any) {
        phone = phone ? phone.toString() : ''
        let numberPhone = phone ? phone.toString() : ''
        if (numberPhone.length === 13) {
            numberPhone = `${phone.substring(0, 3)} (${phone.substring(3, 5)}) ${phone.substring(5, 9)}-${phone.substring(9, phone.length)}`;
        }
        else if (numberPhone.length > 0) {
            numberPhone = `${phone.substring(0, 3)} (${phone.substring(3, 5)}) ${phone.substring(5, 10)}-${phone.substring(10, phone.length)}`;
        }
        return numberPhone
    }

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    async function cleanFilter() {
        reset()
        onChangeFilter(getValues())
    }

    async function deleteUser(id: any) {
        await UserService.deleteUser(id)
            .then((result: any) => {
                toast.success("Usuário desativado com sucesso")
                const formValues = getValues()
                return onChangeFilter(formValues)
            }).catch(() => {
                toast.error("Ocorreu um erro ao desativar o usuário")
            })
    }

    async function activateUser(id: any) {
        await UserService.activateUser(id)
            .then((result: any) => {
                toast.success("Usuário ativado com sucesso")
                const formValues = getValues()
                return onChangeFilter(formValues)
            }).catch(() => {
                toast.error("Ocorreu um erro ao ativar o usuário")
            })
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row className="mx-2" sm={12}>
                    <Col sm={12}>
                        <label>Lista de Usuários</label>
                        <hr className={style.line} />
                    </Col>
                </Row>
                <Row className="mx-2" sm={12}>
                    <Col>
                        <div className="d-flex flex-row-reverse">
                            <Button type={ButtonTypes.button} text={"Criar Usuário"} color={ButtonColors.primary} onClick={toggleRegister} smallButton={true} />
                        </div>
                    </Col>
                </Row>

                <div className={style.card}>
                    <Table bordered responsive >
                        <thead>
                            <tr style={{ textAlign: "center", borderWidth: "0px 0px 2px 0px" }}
                            >
                                <th>
                                    <b>Nome</b>
                                </th>
                                <th>
                                    <b>CRM</b>
                                </th>
                                <th>
                                    <b>Estado</b>
                                </th>
                                <th>
                                    <b>E-mail</b>
                                </th>
                                <th>
                                    <b>
                                        Telefone
                                    </b>
                                </th>
                                <th>
                                    <b>Status</b>
                                </th>
                                {/* <th>
                                    <b>Ambiente</b>
                                </th> */}
                                <th>
                                    <b>Ações</b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ textAlign: "center", borderWidth: "0px 0px 2px 0px" }}>
                                <th>
                                    <Input inputName={"name"} label={""} form={form}
                                    />
                                </th>
                                <th >
                                    <Input inputName={"physicianIdentificationNumber"} label={""} form={form} />
                                </th>
                                <th>
                                    <StateSelect inputName={"physicianState"} label={""} form={form} values={states}></StateSelect>
                                </th>
                                <th>
                                    <Input inputName={"email"} label={""} form={form} />
                                </th>
                                <th>
                                    <Input inputName={"phone"} label={""} form={form} />
                                </th>
                                <th>
                                    <Select inputName={"disabled"} label={""} form={form} values={status}></Select>
                                </th>
                                {/* <th>
                                    <Select inputName={"poolId"} label={""} form={form} values={poolMapped}></Select>
                                </th> */}
                                <th style={{ justifyContent: 'space-evenly', display: 'flex', alignItems: "stretch" }}>
                                        <Button type={ButtonTypes.submit} Icon={FaSearch} color={ButtonColors.flat_primary} smallButton={true} onClick={() => onChangeFilter(getValues())} />
                                        <Button type={ButtonTypes.button} Icon={FaFilterCircleXmark} color={ButtonColors.flat_warn} smallButton={true} onClick={cleanFilter} />
                                </th>
                            </tr>
                            <>
                                {!users || users.isLoading ?
                                    <tr>
                                        <td colSpan={9}>
                                            <div style={{ textAlign: "center" }}>
                                                <Col>
                                                    <Row>
                                                        <div className={style.loaderContainer}>
                                                            <div className={style.spinner}></div>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </div> </td> </tr> :
                                    users?.users.map(
                                        (user: any) =>
                                            <tr key={user['id']} style={{ textAlign: 'left' }} className="text-left">
                                                <th> {user['name']} </th>
                                                <th> {user['physicianIdentificationNumber']}</th>
                                                <th> {user['physicianState']}</th>
                                                <th> {user['email']}</th>
                                                <th>{getUserPhone(user['phone'])}</th>
                                                <th>{user['disabled'] ? 'Inativo' : 'Ativo'}</th>
                                                {/* <th>{poolMapped.find((p: any) => p.value === user['poolId'])?.label}</th> */}
                                                <th style={{ justifyContent: 'space-evenly', display: 'flex' }}>
                                                    <Button type={ButtonTypes.button} Icon={FaUserEdit} color={ButtonColors.flat_primary} smallButton={true} onClick={() => { editUser(user['id']) }} />
                                                    {!user['disabled'] ?
                                                        <Button type={ButtonTypes.button} Icon={FaUserTimes} color={ButtonColors.flat_warn} smallButton={true} onClick={() => { deleteUser(user['id']) }} /> :
                                                        <Button type={ButtonTypes.button} Icon={FaUserCheck} color={ButtonColors.flat_success} smallButton={true} onClick={() => { activateUser(user['id']) }} />
                                                    }
                                                </th>
                                            </tr>
                                    )
                                }

                            </>
                        </tbody>
                    </Table>

                    <PaginationControl
                        page={page}
                        between={4}
                        total={users?.total}
                        limit={10}
                        changePage={(page) => {
                            setPage(page);
                            dispatch(getAdminUsers({
                                data: {},
                                page: page.toString(),
                                limit: "10",
                                filterParams: filterParams
                            }))
                        }}
                        ellipsis={1}
                    />

                </div>
            </Form>
        </>
    );
}

export default UsersList;

