import mobilemedImg from '../../assets/img/mobilemed-icon.svg'
import { Card, Col, Container, Row } from "react-bootstrap";
import Button, { ButtonColors, ButtonTypes } from "../../components/Button";
import { AuthService } from "../../services/AuthService";
import { BiSolidError } from 'react-icons/bi';

export default function NotAuthenticated() {
  async function signIn() {
    const userManager = await AuthService.getUserManager();
    userManager.signinRedirect();
  }

  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get("redirect_url");

  if (redirectUrl) {
    sessionStorage.setItem("portal_redirect", redirectUrl)
  }

  return (
    <Container>
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
        <Card style={{ padding: 24 }}>

          <Card.Title style={{ textAlign: "center" }}>Redirecionando...</Card.Title>
          <div className="text-center mt-4">
            <div className="d-flex align-items-end justify-content-center ">
              <img src={mobilemedImg} alt="MobileMed" style={{ width: '100px' }} />
              <div className="spinner-border spinner-border-sm" role="status" style={{ fontSize: '16px', color: "#005ca8" }} />
            </div>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center mt-4">
            <Button
              color={ButtonColors.flat_primary}
              onClick={() => signIn()}
              type={ButtonTypes.button}
              text="Voltar ao Login" />
          </div>
        </Card>
      </div>
    </Container>
  );
}
