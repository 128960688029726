import {
    MDBTabs,
    MDBTabsContent,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsPane,
} from "mdb-react-ui-kit";
import { Context, createContext, useState } from "react";
import Information from "../Information";
import MedicArea from "../MedicArea";
import Security from "../Security";

import { SessionService } from "../../services/SessionService";

import { useSelector } from "react-redux";
import Administrator from "../Administrator";
import { FaArrowLeft } from "react-icons/fa";


const TabsContext: Context<any> = createContext(null);

function Tabs() {
    const authUser = useSelector((state: any) => state.authReducer.currentUser);
    let isAdmin = false;

    if (authUser) {
        isAdmin = authUser["profile"]["cognito:groups"]?.includes("Admin")
            ? true
            : false;
    }

    const [basicActive, setBasicActive] = useState("tab1");

    const handleBasicClick = (value: string) => {
        if (value === basicActive) {
            return;
        }
        setBasicActive(value);
    };

    function logoutButton() {
        SessionService.logout().then(() => { });
    }

    const portalRedirect = sessionStorage.getItem("portal_redirect");

    return (
        <>
            <MDBTabs className="mb-3">
                <>
                    {portalRedirect && <MDBTabsItem>
                        <MDBTabsLink style={{ color: '#1266f1' }} onClick={() => window.location.href = portalRedirect} active={basicActive === 'tab6'}>
                            <FaArrowLeft style={{ marginRight: '0.5rem' }} />
                            Voltar para o portal
                        </MDBTabsLink>
                    </MDBTabsItem>
                    }
                </>
                <MDBTabsItem>
                    <MDBTabsLink
                        onClick={() => handleBasicClick("tab1")}
                        active={basicActive === "tab1"} >
                        Informações
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                        Segurança
                    </MDBTabsLink>
                </MDBTabsItem>
                {/* <MDBTabsItem>
                                    <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                                        Aplicações
                                    </MDBTabsLink>
                                </MDBTabsItem> */}
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
                        Área médica
                    </MDBTabsLink>
                </MDBTabsItem>
                <>
                    {isAdmin &&
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleBasicClick('tab5')} active={basicActive === 'tab5'}>
                                Administrador
                            </MDBTabsLink>
                        </MDBTabsItem>
                    }
                </>
            </MDBTabs>
            <MDBTabsContent>
                <MDBTabsPane show={basicActive === "tab1"} className="mx-4">
                    <Information />
                </MDBTabsPane>
                <MDBTabsPane show={basicActive === 'tab2'} className="mx-4"><Security /></MDBTabsPane>
                {/* <MDBTabsPane show={basicActive === 'tab3'}><Applications /></MDBTabsPane> */}
                {isAdmin ? <MDBTabsPane show={basicActive === 'tab5'} className="mx-4"><Administrator /></MDBTabsPane> : <></>}
                <MDBTabsPane show={basicActive === "tab4"} className="mx-4">
                    <MedicArea />
                </MDBTabsPane>
            </MDBTabsContent>
        </>
    );
}

export { Tabs, TabsContext };
