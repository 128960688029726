import ReactEasyCrop from "../Croppers/ReactEasyCrop/ReactEasyCrop.jsx";

const ModalWrapper = ({
	showModal,
	onModalClose,
	imgURL,
	onSaveHandler,
    imageRatio
}: any) => {
	return (
        <ReactEasyCrop
            showModal={showModal}
            onModalClose={onModalClose}
            imgURL={imgURL}
            onSaveHandler={onSaveHandler}
            imageRatio={imageRatio}
        />
    );
};

export default ModalWrapper;