import { useState } from "react";
import { toast } from "react-toastify";
import { BsBrowserSafari, BsBrowserFirefox, BsBrowserEdge, BsBrowserChrome } from 'react-icons/bs';
import { PiBrowsers, PiBrowser } from "react-icons/pi";
import { IconContext } from "react-icons";
import UAParser from "ua-parser-js";

export default function BrowserIcon({ userAgent }: any) {
    var parser = new UAParser();
    parser.setUA(userAgent);
    var result = parser.getResult();

    return (
        <>
            <IconContext.Provider value={{ color: "black" }}>
                <span style={{ justifyContent: 'center', display: 'flex', margin: '0.5em 0.4em' }}>
                    {
                        {
                            'Chrome': <BsBrowserChrome />,
                            'Edge': <BsBrowserEdge />,
                            'Firefox': <BsBrowserFirefox/>,
                            'Safari': <BsBrowserSafari/>
                        }[result.browser.name? result.browser.name : ''] 
                        || 
                        <PiBrowser/>
                    }
                </span>
                <span style={{ fontSize: '0.6em', justifyContent: 'center', display: 'flex' }}>
                    {result.browser.version}
                </span>
            </IconContext.Provider>
        </>
    );
}
