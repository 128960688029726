import { combineReducers } from "redux";
import userReducer from "./user/user-slice";
import authReducer from "./authentication/auth-slice";
import adminReducer from "./admin/admin-slice";
import poolReducer from "./pool/pool-slice";

const rootReducer = combineReducers({
    userReducer,
    authReducer,
    adminReducer,
    poolReducer
})

export default rootReducer;