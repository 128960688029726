import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Input } from "../../components/Input/input";
import { useForm } from "react-hook-form";
import { Portal } from "@mui/base";
import { validatePassword, validateRepeatPassword } from "../../utils/validators";
import { useAppDispatch } from "../../redux/store";
import { toast } from "react-toastify";
import { updatePassword } from "../../redux/user/user-thunks";
import Button, { ButtonColors, ButtonTypes } from "../../components/Button";

type AdminUpdatePasswordProps = {
  id: string;
};
export default function UpdatePassword({ id }: AdminUpdatePasswordProps) {
  const [showModal, setShowModal] = useState(false);
  const form = useForm<any>({ defaultValues: {} });
  const { handleSubmit } = form;

  const dispatch = useAppDispatch();

  function onSubmit(data: any) {
    dispatch(updatePassword({ id, newPassword: data.password, oldPassword: data.oldPassword })).then((resp: any) => {
      if (resp && resp.error) {
        return toast.error("Ocorreu um erro a atualizar a senha")
      }

      setShowModal(false);
      form.reset({ password: "", repeatPassword: "", oldPassword: "" });

      return toast.success("Senha atualizada com sucesso")
    })

  }

  const onInvalid = (errors: any) => console.error(errors)
  return (
    <>
      <Button text={"Alterar"} color={ButtonColors.primary} onClick={() => setShowModal(true)} />
      <Portal>
        <Modal show={showModal}>
          <Form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <Modal.Header>
              <Modal.Title>Alterar senha</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Input inputName={"oldPassword"} label={"Senha Antiga"} form={form} type="password" validators={{ validate: validatePassword }} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input inputName={"password"} label={"Nova senha"} form={form} type="password" validators={{ validate: validatePassword }} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input inputName={"repeatPassword"} label={"Repita nova senha"} form={form} type="password" validators={{ validate: validateRepeatPassword(form) }} />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button text={"Cancelar"} color={ButtonColors.warn} onClick={() => setShowModal(false)} type={ButtonTypes.button} />
              <Button text={"Alterar"} color={ButtonColors.primary} type={ButtonTypes.submit}/>
            </Modal.Footer>
          </Form>
        </Modal>
      </Portal>
    </>
  );
}
