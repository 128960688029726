export class UpdateUser {
  id: string
  name?: string
  gender?: string
  phone?: string | null;  // valide + on number
  identificationNumber?: string  // valide cpf
  internalCode?: string
  physicianIdentificationNumber?: string
  physicianState?: string
  recoveryEmail?: string
  birthdate?: Date

  constructor(user: any) {
    this.id = user.id
    this.name = user.name
    this.gender = user.gender
    this.phone = user.phone && user.phone.length !== 0 ? `+${user.phone.replace(/[^a-zA-Z0-9]/g, '')}` : null;
    this.identificationNumber = user.identificationNumber && user.identificationNumber.length !== 0 ? user.identificationNumber?.replace(/[^a-zA-Z0-9]/g, '') : null;
    this.internalCode = user.internalCode && user.internalCode.length !== 0 ? user.internalCode : null
    this.physicianIdentificationNumber = user.physicianIdentificationNumber && user.physicianIdentificationNumber.length !== 0 ? user.physicianIdentificationNumber : null
    this.physicianState = user.physicianState && user.physicianState.length !== 0 ? user.physicianState : null
    this.recoveryEmail = user.recoveryEmail && user.recoveryEmail.length !== 0 ? user.recoveryEmail : null
    this.birthdate = user.birthdate && user.birthdate.length !== 0 ? user.birthdate : null
  }
}