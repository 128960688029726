import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Input } from "../Input/input";
import { useForm } from "react-hook-form";
import styleButton from "../../../../components/Button/Button.module.scss";
import { Portal } from "@mui/base";
import { validatePassword, validateRepeatPassword } from "../../utils/validators";
import { useAppDispatch } from "../../redux/store";
import { adminUpdatePassword } from "../../redux/admin/admin-thunks";
import { toast } from "react-toastify";
import Button, { ButtonColors, ButtonTypes } from "../../components/Button";

type AdminUpdatePasswordProps = {
  id: string;
};
export default function AdminUpdatePassword({ id }: AdminUpdatePasswordProps) {
  const [showModal, setShowModal] = useState(false);
  const form = useForm<any>({ defaultValues: {} });
  const { handleSubmit } = form;

  const dispatch = useAppDispatch();

  function onSubmit(data: any) {
    dispatch(adminUpdatePassword({ id, password: data.password })).then((resp: any) => {
      if (resp && resp.error) {
        return toast.error("Ocorreu um erro a atualizar a senha")
      }

      setShowModal(false);
      form.reset({ password: "", repeatPassword: "" });

      return toast.success("Senha atualizada com sucesso")
    })

  }

  return (
    <>
        <Col sm={4}>
          <Button text="Alterar senha" smallButton={true} color={ButtonColors.flat_primary} type={ButtonTypes.button} onClick={() => setShowModal(true)} />
        </Col>
      <Portal>
        <Modal show={showModal}>
          <Form>
            <Modal.Header>
              <Modal.Title>Alterar senha</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Input inputName={"password"} label={"Nova senha"} form={form} type="password" validators={{ validate: validatePassword }} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input inputName={"repeatPassword"} label={"Repita nova senha"} form={form} type="password" validators={{ validate: validateRepeatPassword(form) }} />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button text={"Cancelar"} color={ButtonColors.warn} onClick={() => setShowModal(false)} />
              <Button text={"Alterar"} color={ButtonColors.primary}
                onClick={handleSubmit(onSubmit)}
              />
            </Modal.Footer>
          </Form>
        </Modal>
      </Portal>
    </>
  );
}
