import { createSlice, Slice } from "@reduxjs/toolkit";
import { UserState } from "../classes/UserState";
import { createUser, getAdminUsers, getCurrentUser, getLogUsers, getQRCode, getUserLogs, getUserSessions, getUserStates, removeAllUserSessions, removeMFACode, removeUserSession, setMFACode, updateCurrentUser, updateSignature, updateUser } from "./user-thunks";

const initialState = new UserState();

const userSlice: Slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    closeUserList: (state, action) => {
      return { ...state, adminFirstLoad: false };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        return { ...state, loading: true, error: null, currentUser: null };
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        return { ...state, loading: false, currentUser: action.payload };
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        return { ...state, loading: false, error: action.error.message, currentUser: null };
      })

      .addCase(getUserStates.pending, (state) => { 
        return { ...state, userStateloading: true, userStateError: null, states: [] };
      })
      .addCase(getUserStates.fulfilled, (state, action) => {
        return { ...state, userStateloading: false, states: action.payload };
      })
      .addCase(getUserStates.rejected, (state, action) => {
        return { ...state, userStateloading: false, userStateError: action.error.message, states: [] };
      })

      .addCase(getUserLogs.pending, (state) => {
        return { ...state, logsLoading: false, logsError: null, logs: null };
      })
      .addCase(getUserLogs.fulfilled, (state, action) => {
        return { ...state, logsLoading: false, logs: action.payload };
      })
      .addCase(getUserLogs.rejected, (state, action) => {
        return { ...state, logsLoading: false, logsError: action.error.message, logs: null };
      })

      .addCase(getAdminUsers.fulfilled, (state, action) => {
        return { ...state, adminUsersLoading: false, adminUsers: action.payload };
      })
      .addCase(getAdminUsers.pending, (state) => {
        return { ...state, adminUsersLoading: false, adminUserError: null, adminUsers: null, adminFirstLoad: true };
      })
      .addCase(getAdminUsers.rejected, (state, action) => {
        return { ...state, adminUsersLoading: false, adminUserError: action.error.message, adminUsers: null };
      })

      .addCase(createUser.fulfilled, (state, action) => {
        return { ...state, registerState: action.payload, registerLoading: false, updateError: null };
      })
      .addCase(createUser.pending, (state, action) => {
        return { ...state, registerState: null, registerLoading: true, updateError: null };
      })
      .addCase(createUser.rejected, (state, action) => {
        return { ...state, registerState: null, registerLoading: false, updateError: action.error.message };
      })

      .addCase(updateUser.fulfilled, (state, action) => {
        return { ...state, updateState: action.payload, updateLoading: false, updateError: null };
      })
      .addCase(updateUser.pending, (state, action) => {
        return { ...state, updateState: null, updateLoading: true, updateError: null };
      })
      .addCase(updateUser.rejected, (state, action) => {
        return { ...state, updateState: null, updateLoading: false, updateError: action.error.message };
      })

      .addCase(getQRCode.fulfilled, (state, action) => {
        return { ...state, qrcodeState: action.payload, qrcodeLoading: false, qrcodeError: null };
      })
      .addCase(getQRCode.pending, (state, action) => {
        return { ...state, qrcodeState: null, qrcodeLoading: true, qrcodeError: null };
      })
      .addCase(getQRCode.rejected, (state, action) => {
        return { ...state, qrcodeState: null, qrcodeLoading: false, qrcodeError: action.error.message };
      })

      .addCase(setMFACode.fulfilled, (state, action) => {
        return { ...state, setMFACodeState: action.payload, setMFACodeLoading: false, setMFACodeError: null };
      })
      .addCase(setMFACode.pending, (state, action) => {
        return { ...state, setMFACodeState: null, setMFACodeLoading: true, setMFACodeError: null };
      })
      .addCase(setMFACode.rejected, (state, action) => {
        return { ...state, setMFACodeState: null, setMFACodeLoading: false, setMFACodeError: action.error.message };
      })

      .addCase(removeMFACode.fulfilled, (state, action) => {
        return { ...state, removeMFACodeState: action.payload, removeMFACodeLoading: false, removeMFACodeError: null };
      })
      .addCase(removeMFACode.pending, (state, action) => {
        return { ...state, removeMFACodeState: null, removeMFACodeLoading: true, removeMFACodeError: null };
      })
      .addCase(removeMFACode.rejected, (state, action) => {
        return { ...state, removeMFACodeState: null, removeMFACodeLoading: false, removeMFACodeError: action.error.message };
      })
      
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        return { ...state, updateCurrentUserState: action.payload, updateCurrentUserLoading: false, updateCurrentUserError: null };
      })
      .addCase(updateCurrentUser.pending, (state, action) => {
        return { ...state, updateCurrentUserState: null, updateCurrentUserLoading: true, updateCurrentUserError: null };
      })
      .addCase(updateCurrentUser.rejected, (state, action) => {
        return { ...state, updateCurrentUserState: null, updateCurrentUserLoading: false, updateCurrentUserError: action.error.message };
      })
      
      .addCase(updateSignature.fulfilled, (state, action) => {
        return { ...state, updateSignatureState: action.payload, updateSignatureLoading: false, updateSignatureError: null };
      })
      .addCase(updateSignature.pending, (state, action) => {
        return { ...state, updateSignatureState: null, updateSignatureLoading: true, updateSignatureError: null };
      })
      .addCase(updateSignature.rejected, (state, action) => {
        return { ...state, updateSignatureState: null, updateSignatureLoading: false, updateSignatureError: action.error.message };
      })

      .addCase(getUserSessions.fulfilled, (state, action) => {
        return { ...state, userSessionsLoading: false, userSessions: action.payload };
      })
      .addCase(getUserSessions.pending, (state) => {
        return { ...state, userSessionsLoading: false, sessionError: null, userSessions: null, sessionFirstLoad: true };
      })
      .addCase(getUserSessions.rejected, (state, action) => {
        return { ...state, userSessionsLoading: false, sessionError: action.error.message, userSessions: null };
      })

      .addCase(removeUserSession.fulfilled, (state, action) => {
        return { ...state, removeUserSessionState: action.payload, removeUserSessionLoading: false, removeUserSessionError: null };
      })
      .addCase(removeUserSession.pending, (state, action) => {
        return { ...state, removeUserSessionState: null, removeUserSessionLoading: true, removeUserSessionError: null };
      })
      .addCase(removeUserSession.rejected, (state, action) => {
        return { ...state, removeUserSessionState: null, removeUserSessionLoading: false, removeUserSessionError: action.error.message };
      })

      .addCase(removeAllUserSessions.fulfilled, (state, action) => {
        return { ...state, removeUserSessionState: action.payload, removeUserSessionLoading: false, removeUserSessionError: null };
      })
      .addCase(removeAllUserSessions.pending, (state, action) => {
        return { ...state, removeUserSessionState: null, removeUserSessionLoading: true, removeUserSessionError: null };
      })
      .addCase(removeAllUserSessions.rejected, (state, action) => {
        return { ...state, removeUserSessionState: null, removeUserSessionLoading: false, removeUserSessionError: action.error.message };
      })

      .addCase(getLogUsers.fulfilled, (state, action) => {
        return { ...state, logUsersLoading: false, logUsers: action.payload };
      })
      .addCase(getLogUsers.pending, (state) => {
        return { ...state, logUsersLoading: false, logUserError: null, logUsers: null, logFirstLoad: true };
      })
      .addCase(getLogUsers.rejected, (state, action) => {
        return { ...state, logUsersLoading: false, logUserError: action.error.message, logUsers: null };
      })
  },
});

export const { closeUserList } = userSlice.actions;
export default userSlice.reducer;
