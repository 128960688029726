import style from "./input.module.scss";
import { Form } from "react-bootstrap";
import WarningCard from "../WarningCard";
import InputMask from 'react-input-mask';
import { HTMLInputTypeAttribute, InputHTMLAttributes } from "react";

type InputProps = {
  inputName: string;
  label: string;
  mask?: string;
  disabled?: boolean;
  validators?: any;
  form: any;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  required?: boolean;
};

export function Input({ inputName, label, form, validators, disabled, mask, type, required }: InputProps) {
  const { register, formState: { errors } } = form;
  const validatorsKeys = Object.keys(validators || {});

  return (
    <Form.Group className={`${label ? "mt-4 mb-2" : ""}`} controlId="formGroupName" style={{ width: "100%" }}>
      {label && <Form.Label>{label}</Form.Label>}

      {mask ?
        <InputMask id={inputName} className={`${style.input} ${errors[inputName] ? style.fieldError : ''}`} mask={mask} maskPlaceholder={""} style={{ width: "100%", }} disabled={disabled}  {...register(inputName, validators)} /> :
        <input id={inputName} className={`${style.input} ${errors[inputName] ? style.fieldError : ''}`} disabled={disabled} style={{ width: "100%" }}
          type={type}
          {...register(inputName, validators)} />
      }

      {validatorsKeys.map((key) => {
        if (errors[inputName] && errors[inputName].type === key) {
          return <WarningCard key={key} errorMessage={`error${inputName.charAt(0).toUpperCase() + inputName.slice(1)}`}></WarningCard>
        }

        return <div key={key}></div>
      })}
    </Form.Group>
  );
}
