import { createAsyncThunk } from "@reduxjs/toolkit";
import { EstadosService } from "../../services/EstadosService";
import { UserService } from "../../services/UsersService";
import { CreateUser } from "../../DTOs/CreateUser";

export const getCurrentUser = createAsyncThunk("user/getCurrentUser", async () => {
    const response = await UserService.getAuthorization();
    const data = await response.data;
    if(data.birthdate){
        data.birthdate = data.birthdate.split('T')[0]
    }
    return data;
});

export const updateCurrentUser = createAsyncThunk("user/updateCurrentUser", async (user: any) => {
    const response = await UserService.updateUser(user);
    const data = await response.data;
    return data;
});

export const getUserStates = createAsyncThunk("user/getUserStates", async (id?: any | undefined) => {
    const response = await EstadosService.getStates(id || "BR");
    const data = await response.data;
    return data;
});

export const updateUserPhoto = createAsyncThunk("user/updateUserPhoto", async (params: any) => {
    const response = await UserService.updatePhoto(params);
    const data = await response.data;
    return data;
});

export const getUserLogs = createAsyncThunk("user/getUserLogs", async ({ user, page }: any) => {
    const response = await UserService.getLogs("", user, page);
    const data = await response.data;
    return data;
});

export const getAdminUsers = createAsyncThunk("user/getAdminUsers", async (params: getAdminUserParams) => {
    const response = await UserService.getUsers(params.data, params.page, params.limit, params.filterParams);
    const data = await response.data;
    return data;
});

export const getLogUsers = createAsyncThunk("user/getLogUsers", async (params: getAdminUserParams) => {
    const response = await UserService.getUsers(params.data, params.page, params.limit, params.filterParams);
    const data = await response.data;
    return data;
});

export const createUser = createAsyncThunk("user/createUser", async (params: CreateUser) => {
    try {
        const response = await UserService.createUser(params);
        const data = await response.data;
        return data;
    } catch (error: any) {
        throw new Error(error.response.data.message)
    }
})

export const createExternalUser = createAsyncThunk("user/createUser", async (params: createExternalUserParams) => {
    try {
        const response = await UserService.createExternalUser(params.params, params.token);
        const data = await response.data;
        return data;
    } catch (error: any) {
        throw new Error(error.response.data.message)
    }
})

export const updateSignature = createAsyncThunk("user/updateSignature", async ({ id, signFormData }: updateSignatureParams) => {
    const response = await UserService.updateSignature(id, signFormData);
    const data = await response.data;
    return data;
})

export const updateUser = createAsyncThunk("user/updateUser", async (user: any) => {
    const response = await UserService.updateUser(user);
    const data = await response.data;
    return data;
});

export const updatePassword = createAsyncThunk("user/updatePassword", async (params: updatePasswordParams) => {
    const response = await UserService.resetPassword(params.id, params.oldPassword, params.newPassword);
    const data = await response.data;
    return data;
});

export const getQRCode = createAsyncThunk("user/getQRCode", async () => {
    const response = await UserService.getMFAUrl();
    const data = await response.data;
    return data;
});

export const setMFACode = createAsyncThunk("user/setMFACode", async (code: string) => {
    const response = await UserService.setMFACode(code);
    const data = await response.data;
    return data;
});

export const removeMFACode = createAsyncThunk("user/removeMFACode", async () => {
    const response = await UserService.deleteMFA();
    const data = await response.data;
    return data;
});

export const getUserSessions = createAsyncThunk("user/getUserSessions", async (user: any) => {
    const response = await UserService.getUserSessions(user);
    const data = await response.data;
    return data;
});

export const removeUserSession = createAsyncThunk("user/removeUserSession", async (params: removeUserSessionParams) => {
    const response = await UserService.deleteUserSession(params.sessionId, params.userId);
    const data = await response.data;
    return data;
});

export const removeAllUserSessions = createAsyncThunk("user/removeAllUserSessions", async (params: removeAllUserSessionsParams) => {
    const response = await UserService.deleteAllUserSession(params.userId);
    const data = await response.data;
    return data;
});

type getAdminUserParams = {
    data: any;
    page: string;
    limit: string;
    filterParams: any;
}

type updateSignatureParams = {
    id: string,
    signFormData: FormData
}

type updatePasswordParams = {
    id: string,
    oldPassword: string
    newPassword: string
}

type createExternalUserParams = {
    params: CreateUser,
    token: string
}

type removeUserSessionParams = {
    sessionId: string,
    userId: string
}

type removeAllUserSessionsParams = {
    userId: string
}