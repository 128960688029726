import { useSelector } from "react-redux";
import UsersList from "../../components/UsersList";
import { AdminStateEnum } from "../../redux/classes/AdminState";
import UserForm, { UserFormModeEnum } from "../../components/UserForm/UserForm";

export default function Administrator() {
  const state = useSelector((state: any) => state.adminReducer);

  switch (state.state) {
    case AdminStateEnum.USERS_LIST:
      return <UsersList />
    case AdminStateEnum.REGISTER_USER:
      return <UserForm userFormMode={UserFormModeEnum.ADMIN_REGISTER} />
    case AdminStateEnum.UPDATE_USER:
      return <UserForm userFormMode={UserFormModeEnum.ADMIN_EDIT} user={state.userEdit} />
    default: 
      return <UsersList />
  }
}

