import WarningCard from "../WarningCard";

type CheckboxProps = {
  inputName: string;
  label: string;
  disabled?: boolean;
  validators?: any;
  onChange?: any;
  form: any;
};

export function Checkbox({form, inputName, label, validators, disabled, onChange}: CheckboxProps) {
  const { register, formState: { errors } } = form;
  
  return (
    <div className="form-check">
      <input className="form-check-input" type="checkbox" value="" id={inputName} disabled={disabled}  {...register(inputName, validators)} onChange={onChange} style={{
        backgroundImage: 'none',
      }}/>
      <label className="form-check-label" htmlFor={inputName}>
        {label}
      </label>
    </div>
  )
}