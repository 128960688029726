import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/js/src/collapse.js";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { Route, Routes } from "react-router-dom";
import MyProfile from "../MyProfile/MyProfile";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './toast.colors.module.scss';
import { ExternalRegister } from "../ExternalRegister/ExternalRegister";
import ProtectedRoutes from "../../components/ProtectedRoutes/ProtectedRoutes";
import { VerifyEmail } from "../VerifyEmail/VerifyEmail";

export default function App() {
  return (
    <>
      <ToastContainer hideProgressBar={true} theme="colored" icon={""} autoClose={3000} />
      <Routes>
        <Route path='/:pool_id/:pool_tenant' element={<ProtectedRoutes />}>
          <Route element={<MyProfile />} index />
        </Route>
        <Route path="/*" element={<ProtectedRoutes />} >
          <Route element={<MyProfile />} index />
        </Route>
        <Route path="register" element={<ExternalRegister />} />
        <Route path="verify" element={<VerifyEmail />} />
      </Routes>
    </>
  );
}
