import "bootstrap/dist/css/bootstrap.css";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { useSelector } from "react-redux";
import NotAuthenticated from "../NotAuthenticated";
import { Tabs } from "../Tabs/tabs";
import { ErrorPage } from "../Error";
import { OIDC_DEFAULT_TENANT, OIDC_DEFAULT_USER_POOL_ID } from "../../shared/Config";

export default function MyProfile() {
  const isAuthenticated = useSelector((state: any) => state.authReducer.isAuthenticated);
  const error = useSelector((state: any) => state.userReducer.error);
  if(error) {
    return <ErrorPage />;
  }

  if (!isAuthenticated) {
    return <NotAuthenticated />;
  }

  const parameters = window.location.pathname.split('/')

  if(parameters.length < 3) {
    window.location.href = `/${OIDC_DEFAULT_USER_POOL_ID}/${OIDC_DEFAULT_TENANT}/`;
    return <></>
  }

  return (
      <Tabs />
  );
}
