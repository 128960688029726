import { AuthService } from "./AuthService"

function removeCodeFromUrl() {
  let newUrl = window.location.href.replace(/[?&]code=[^&]+/, '');
  newUrl = window.location.href.replace(/[&]state=[^&]+/, '');

  window.history.replaceState({}, document.title, newUrl);
}

export const SessionService = {
  getAccessToken: function () {
    removeCodeFromUrl()
    return new Promise<string>(async (resolve, reject) => {
      let userManager = await AuthService.getUserManager();
      let auth = await userManager.getUser()

      if (!auth) {
        return reject('No user')
      }

      let token = auth.access_token
      const decode = JSON.parse(atob(token.split('.')[1]));

      if (decode.exp * 1000 < new Date().getTime()) {
        try {
          await userManager.signinSilent()

          let user = await userManager.getUser()

          if (user) {
            return resolve(user.access_token)
          }

          return reject('No user')
        } catch (error) {
          userManager.signinRedirect();
        }

      } else {
        return resolve(token)
      }
    })
  },
  getRefreshToken: function () {
    removeCodeFromUrl()
    return new Promise<string>(async (resolve, reject) => {
      let userManager = await AuthService.getUserManager();
      let auth = await userManager.getUser()

      if (!auth) {
        return reject('No user')
      }

      let token = auth.refresh_token

      if (token) {
        resolve(token)
      } else {
        reject('no Token')
      }
    })
  },
  logout: function () {
    return new Promise(async (resolve, reject) => {
      let userManager = await AuthService.getUserManager();
      let auth = await userManager.getUser()
      if (!auth) {
        return reject('No user')
      }

      try {
        await userManager.revokeTokens(["refresh_token"])
        await userManager.signoutRedirect({
          post_logout_redirect_uri: window.location.origin,
        })

        return resolve("")
      } catch (error) {
        return reject(error)
      }
    })
  },
  getURLParams: function () {
    const URLparams = window.location.pathname.split('/')
    const poolParams = URLparams[1]
    const poolTenant = URLparams[2]
    return { poolParams, poolTenant }
  },
}
