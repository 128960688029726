import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Portal } from "@mui/base";
import { useAppDispatch } from "../../redux/store";
import { toast } from "react-toastify";
import Button, { ButtonColors, ButtonTypes } from "../Button";
import { useSelector } from "react-redux";
import { getCurrentUser, removeUserSession } from "../../redux/user/user-thunks";

import { TiDelete } from "react-icons/ti";
import { IconContext } from "react-icons";

export default function RemoveSession({ sessionId, userId }: any) {
    const [showModal, setShowModal] = useState(false);
    const removeUserSessionLoading : any = useSelector((state: any) => state.userReducer.removeUserSessionLoading)

    const dispatch = useAppDispatch();

    function onSubmit() {
        dispatch(removeUserSession({ sessionId: sessionId, userId: userId })).then((resp: any) => {
            if (resp && resp.error) {
                return toast.error("Ocorreu um erro ao remover a sessão")
            }

            dispatch(getCurrentUser())

            setShowModal(false)
            return toast.success("Sessão desconectada com sucesso")
        });
    }

    return (
        <>
        <div title="Desconectar" onClick={() => setShowModal(true)}>
            <IconContext.Provider value={{ color: "red" }}>
                <span style={{ justifyContent: 'center', display: 'flex', fontSize: '2em', }}>
                    <TiDelete />
                </span>
            </IconContext.Provider>
        </div>
            <Portal>
                <Modal show={showModal}>
                    <Modal.Header>
                        <Modal.Title>Remover sessão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Deseja realmente DESCONECTAR essa sessão?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text={"Cancelar"} color={ButtonColors.warn} onClick={() => setShowModal(false)} disabled={removeUserSessionLoading} />
                        <Button text={"Desconectar"} color={ButtonColors.primary} onClick={() => onSubmit()} isLoading={removeUserSessionLoading} />
                    </Modal.Footer>
                </Modal>
            </Portal>
        </>
    );
}
