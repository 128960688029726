import axios from 'axios';
import { API_BASE_LOG_URL, API_BASE_URL, CLIENT_ID, OIDC_AUTHORITY, OIDC_DEFAULT_TENANT, OIDC_DEFAULT_USER_POOL_ID } from '../shared/Config';
import { SessionService } from './SessionService';
import { CreateUser } from '../DTOs/CreateUser';

export const UserService = {

  getAuthorization: async function () {
    let token = await SessionService.getAccessToken()
    return axios({
      url: `${API_BASE_URL}/user/token/userinfo`,
      headers: { Authorization: token },
      method: "get",
    })
  },

  getUsers: async function (userData: any, page: string, limit: string, filterParams: any) {
    let token = await SessionService.getAccessToken();
    return axios({
      url: `${API_BASE_URL}/user?page=${page}&limit=${limit}${filterParams}`,
      method: "get",
      headers: { Authorization: token },
    });
  },
  getUser: async function (id: string) {
    let token = await SessionService.getAccessToken()

    return axios({
      url: `${API_BASE_URL}/user/${id}`,
      headers: { Authorization: token },
      method: "get",
    })
  },
  deleteUser: async function (id: number) {
    const token = await SessionService.getAccessToken();
    return axios({
      url: `${API_BASE_URL}/user/${id}`,
      method: "delete",
      headers: { Authorization: token },
    })
  },
  activateUser: async function (id: number) {
    const token = await SessionService.getAccessToken();
    return axios({
      url: `${API_BASE_URL}/user/${id}/activate`,
      method: "post",
      headers: { Authorization: token },
    })
  },

  createUser: async function (params: CreateUser) {
    const token = await SessionService.getAccessToken();

    return axios({
      url: `${API_BASE_URL}/user`,
      headers: { Authorization: token },
      method: "post",
      data: params
    })
  },

  createExternalUser: async function (params: CreateUser, token: string) {
    return axios({
      url: `${API_BASE_URL}/user/email/token`,
      method: "post",
      data: {
        user: params,
        token: token
      }
    })
  },

  async updateUser(params: any) {
    let token = await SessionService.getAccessToken()

    return axios({
      url: `${API_BASE_URL}/user/${params?.id}`,
      headers: { Authorization: token },
      method: "patch",
      data: params
    })
  },

  async updatePhoto(data: any) {
    let token = await SessionService.getAccessToken()
    return axios({
      url: `${API_BASE_URL}/user/${data.user}/profilePhoto`,
      headers: { Authorization: token },
      method: "put",
      data: data.picture
    })
  },

  async updateSignature(id: any, picture: any) {
    let token = await SessionService.getAccessToken()
    return axios({
      url: `${API_BASE_URL}/user/${id}/signPhoto`,
      headers: { Authorization: token },
      method: "put",
      data: picture
    })
  },

  getMFAUrl: async function () {
    let token = await SessionService.getAccessToken()
    let refreshToken = await SessionService.getRefreshToken()
    let params = SessionService.getURLParams()
    let client_id = sessionStorage.getItem(`currentClientId_${params.poolParams}`) || CLIENT_ID

    return axios({
      url: `${API_BASE_URL}/user/TOTP/enable`,
      headers: { Authorization: token },
      data: {
        poolId: params.poolParams || OIDC_DEFAULT_USER_POOL_ID,
        tenant: params.poolTenant || OIDC_DEFAULT_TENANT,
        refresh_token: refreshToken,
        client_id: client_id
      },
      method: "post",
    })
  },

  async getUserPools() {
    let token = await SessionService.getAccessToken()
    return axios({
      url: `${API_BASE_URL}/pool`,
      headers: { Authorization: token },
      method: "get",
    })
  },

  setMFACode: async function (code: any) {
    let token = await SessionService.getAccessToken()
    let refreshToken = await SessionService.getRefreshToken()
    let params = SessionService.getURLParams()
    let client_id = sessionStorage.getItem(`currentClientId_${params.poolParams}`) || CLIENT_ID

    return axios({
      url: `${API_BASE_URL}/user/TOTP/verify`,
      headers: { Authorization: token },
      method: "post",
      data: {
        code: code,
        refresh_token: refreshToken,
        client_id: client_id,
        poolId: params.poolParams || OIDC_DEFAULT_USER_POOL_ID,
        tenant: params.poolTenant || OIDC_DEFAULT_TENANT
      }
    })
  },

  setFilters(token: any, filterParams: any, page: string, limit: string) {
    return axios({
      url: `${API_BASE_URL}/user?page=${page}&limit=${limit}${filterParams}`,
      headers: { Authorization: token },
      method: "get",
    })
  },

  deleteMFA: async function () {
    let token = await SessionService.getAccessToken()
    let refreshToken = await SessionService.getRefreshToken()
    let params = SessionService.getURLParams()
    let client_id = sessionStorage.getItem(`currentClientId_${params.poolParams}`) || CLIENT_ID

    return axios({
      url: `${API_BASE_URL}/user/TOTP/disable`,
      method: "delete",
      headers: { Authorization: token },
      data: {
        refresh_token: refreshToken,
        client_id: client_id,
        poolId: params.poolParams || OIDC_DEFAULT_USER_POOL_ID,
        tenant: params.poolTenant || OIDC_DEFAULT_TENANT
      }
    })
  },
  adminResetPassword: async function (id: any, newPassword: any) {
    let token = await SessionService.getAccessToken()
    return axios({
      url: `${API_BASE_URL}/user/${id}/adminpassword`,
      method: "put",
      headers: { Authorization: token },
      data: {
        "newPassword": `${newPassword}`
      }
    })
  },
  adminRemoveMFA: async function (id: any) {
    let token = await SessionService.getAccessToken()
    return axios({
      url: `${API_BASE_URL}/user/${id}/admin/TOTP`,
      method: "delete",
      headers: { Authorization: token },
    })
  },
  resetPassword: async function (id: any, oldPassword: any, newPassword: any) {
    let token = await SessionService.getAccessToken()
    let refreshToken = await SessionService.getRefreshToken()
    let params = SessionService.getURLParams()
    let client_id = sessionStorage.getItem(`currentClientId_${params.poolParams}`) || CLIENT_ID

    return axios({
      url: `${API_BASE_URL}/user/${id}/password`,
      method: "put",
      headers: { Authorization: token },
      data: {
        "newPassword": `${newPassword}`,
        "oldPassword": `${oldPassword}`,
        refresh_token: refreshToken,
        client_id: client_id,
        poolId: params.poolParams || OIDC_DEFAULT_USER_POOL_ID,
        tenant: params.poolTenant || OIDC_DEFAULT_TENANT
      }
    })
  },

  getLogs: async function (token: any, id: any, page: any) {
    token = await SessionService.getAccessToken()
    return axios({
      url: `${API_BASE_LOG_URL}`,
      method: "post",
      headers: { Authorization: token },
      data: {
        "entityId": id,
        "entityName": 'user',
        "page": page
      }
    })
  },

  getUserSessions: async function (id: any) {
    let token = await SessionService.getAccessToken()
    let params = SessionService.getURLParams()
    return axios({
      url: `${OIDC_AUTHORITY}/${params.poolParams}/${params.poolTenant}/session/user/${id}`,
      headers: { Authorization: token },
      method: "get"
    })
  },

  deleteUserSession: async function (sessionId: any, userId: any) {
    let token = await SessionService.getAccessToken()
    let params = SessionService.getURLParams()
    return axios({
      url: `${OIDC_AUTHORITY}/${params.poolParams}/${params.poolTenant}/session/${sessionId}/user/${userId}`,
      method: "delete",
      headers: { Authorization: token },
    })
  },

  deleteAllUserSession: async function (userId: any) {
    let token = await SessionService.getAccessToken()
    let params = SessionService.getURLParams()
    return axios({
      url: `${OIDC_AUTHORITY}/${params.poolParams}/${params.poolTenant}/session/user/${userId}`,
      method: "delete",
      headers: { Authorization: token },
    })
  },

  sendVerificationEmail: async function (id: any) {
    let token = await SessionService.getAccessToken()

    return axios({
      url: `${API_BASE_URL}/user/${id}/verification/email`,
      method: "get",
      headers: { Authorization: token }
    })
  },

  setEmailAsVerified: async function (id: any) {
    let token = await SessionService.getAccessToken()

    return axios({
      url: `${API_BASE_URL}/user/${id}/verification/admin/email`,
      method: "POST",
      headers: { Authorization: token }
    })
  },

  sendCodeEmail: async function (id: any, code: string) {
    return axios({
      url: `${API_BASE_URL}/user/${id}/verification/email`,
      method: "post",
      data: {
        code
      }
    })
  },
};
