import style from "../home/App.module.scss";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { Row } from "react-bootstrap";
import imgSignature from "../../assets/img/mobilemed-assinatura.jpg"
import { useSelector } from "react-redux";
import { Input } from "../../components/Input/input";
import { useAppDispatch } from "../../redux/store";
import { getCurrentUser, updateCurrentUser, updateSignature } from "../../redux/user/user-thunks";
import { StateSelect } from "../../components/StateSelect/stateSelect";
import { SignPhoto } from "../../components/SignPhoto/signPhoto";
import { toast } from "react-toastify";
import { UpdateUser } from "../../DTOs/UpdateUser";
import Button, { ButtonColors, ButtonTypes } from "../../components/Button";
import LoadingWrapper from "../../components/Loading";

function MedicArea() {

  const dispatch = useAppDispatch();
  const user = useSelector((state: any) => state.userReducer.currentUser);
  const userLoading = useSelector((state: any) => state.userReducer.loading);
  const updateLoading = useSelector((state: any) => state.userReducer.updateCurrentUserLoading);
  const updateSignLoading = useSelector((state: any) => state.userReducer.updateSignatureLoading);
  const states: any = useSelector((state: any) => state.userReducer.states.states);

  const form: any = useForm({ defaultValues: user || {}, delayError: 700 })

  if (user && user.id && form.getValues().id !== user.id) {
    form.reset(user)
  }

  const { handleSubmit } = form;

  const onSubmit = async (data: any) => {
    (dispatch(updateCurrentUser(new UpdateUser(data))))
      .then((resp: any) => {
        if(resp && resp.error) {
          return toast.error("Ocorreu um erro ao atualizar o usuário")
        }

        dispatch(getCurrentUser())
        toast.success("Usuário atualizado com sucesso!")
      })
  }

  function updateSignUser(signFormData: any) {
    dispatch(updateSignature({ id: user.id, signFormData }))
      .then(resultSignPhoto => {
        if (!resultSignPhoto || !resultSignPhoto.payload || !resultSignPhoto.payload.id) {
          return toast.error("Erro ao salvar a assinatura do usuário!")
        }

        dispatch(getCurrentUser())
        return toast.success("Assinatura salva com sucesso!")
      })
  }

  return (
    <>
      <LoadingWrapper isLoading={userLoading}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row sm={12}>
            <Col sm={5}>
              <label>Informações</label>
              <hr className={style.line} />
              <Row style={{ paddingRight: '2em' }}>
                <Col md={6}>
                  <Input inputName={"physicianIdentificationNumber"} label={"CRM"} form={form} validators={{ required: true }} />
                </Col>
                <Col md={6}>
                  <StateSelect inputName={"physicianState"} label={"Estado"} form={form} values={states}></StateSelect>
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <label>Assinatura 420x120px</label>
              <hr className={style.line} />
              <Row>
                <Col>
                  <SignPhoto currentSignLink={user && user.signLink ? user.signLink : ""} onSignChange={updateSignUser} />
                </Col>
              </Row>
              <Row>
                <Col style={{ marginLeft: 5 }}>
                  <a style={{ fontWeight: 'bold' }} href={imgSignature} download="mobilemed-assinatura">
                    Download do modelo de assinatura
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-5">
            <Button text={"Salvar"} color={ButtonColors.primary} type={ButtonTypes.submit} isLoading={updateLoading || updateSignLoading} />
          </div>
        </Form>
      </LoadingWrapper>
    </>
  );
}

export default MedicArea;
