import style from "../../pages/home/App.module.scss";
import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import ModalWrapper from "../ModalWrapper";
import imgSignature from "../../assets/img/mobilemed-assinatura.jpg"
import { FaCamera } from "react-icons/fa";

type SignPhotoProps = {
  currentSignLink?: string;
  onSignChange: (signLink: FormData) => void;
}

export function SignPhoto({ currentSignLink, onSignChange }: SignPhotoProps) {
  const [showModal, setShowModal] = useState(false);

  const [photoUser, setImgUser] = useState(currentSignLink ? currentSignLink : imgSignature);
  const [croppedImg, setCroppedImg] = useState(currentSignLink ? currentSignLink : imgSignature);

  const handleFileSelect = async (e: any) => {
    try {
      e.preventDefault();
      let files;

      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }

      const reader = new FileReader();

      if (!reader) return;

      reader.onload = () => {
        let result = reader.result ? reader.result : "";
        setImgUser(result.toString());
        e.target.value = null;
        setShowModal(true);
      };

      reader.readAsDataURL(files[0]);
    } catch (error) {
    }
  };

  useEffect(() => {
    if (!croppedImg && currentSignLink) {
      setCroppedImg(currentSignLink)
    }
  }, [currentSignLink]);

  useEffect(() => {
  }, [showModal]);

  useEffect(() => {
    modalImgUser()
  }, [croppedImg]);

  async function modalImgUser() {
    var updatedPhoto = croppedImg.match("blob")
    if (!!updatedPhoto) {
      var formData = new FormData();
      const response = await fetch(croppedImg);
      const blob = await response.blob();
      formData.append("file", blob);

      onSignChange(formData);
    }
  }

  return (
    <>
      <div>
        <div className={style.hoverSignUser}>
          <div className={style.iconLogoHover}>
            <FaCamera />
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileSelect} id="sign-photo" />
          <label htmlFor="sign-photo">
            <Image
              id="userImg"
              src={croppedImg}
              className={style.signImg} />
          </label>
        </div>
      </div>
      <ModalWrapper
        showModal={showModal}
        imgURL={photoUser}
        imageRatio={420 / 120}
        onSaveHandler={setCroppedImg}
        onModalClose={() => {
          setShowModal(false);
        }}
      />
    </>
  )
}