import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Input } from "../Input/input";
import { useForm } from "react-hook-form";
import { Portal } from "@mui/base";
import { useAppDispatch } from "../../redux/store";
import { getCurrentUser, getQRCode, setMFACode } from "../../redux/user/user-thunks";
import Button, { ButtonColors, ButtonTypes } from "../Button";
import { QRCodeSVG } from "qrcode.react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

type AdminRegisterMFAProps = {
  id: string;
};

export default function RegisterMFA({ id }: AdminRegisterMFAProps) {
  const [showModal, setShowModal] = useState(false);
  const form = useForm<any>({ defaultValues: {} });
  const { handleSubmit } = form;
  const user = useSelector((state: any) => state.userReducer);

  const dispatch = useAppDispatch();

  function openModal() {
    dispatch(getQRCode())
    setShowModal(true);
  }

  function onSubmit(data: any) {
    dispatch(setMFACode(data.MFAcode))
      .then((resp: any) => {
        if (resp && resp.error) {
          return toast.error("Ocorreu um erro a configurar o autenticador")
        }

        dispatch(getCurrentUser())
        setShowModal(false);
        form.reset({ code: "" });

        return toast.success("Autenticador configurado com sucesso")
      })
  }

  return (
    <>
      <Button text={"Configurar"} color={ButtonColors.primary} onClick={() => openModal()} />
      <Portal>
        <Modal show={showModal}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>Configurador do autenticador móvel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>1. Instale um autenticador no seu celular:</p>
              <p>
                <li>Google Autheticator</li>
                <li>Microsoft Authenticator</li>
              </p>
              <p>2. Escaneie o QR Code abaixo</p>
              <div style={{ textAlign: 'center' }}>
                <p>
                  {
                    user.qrcodeLoading ?
                      <div className="spinner-border spinner-border-sm" role="status" /> :
                      <QRCodeSVG value={user && user.qrcodeState && user.qrcodeState.url ? user.qrcodeState.url : ""} />
                  }
                </p>
              </div>
              <p>3. Digite o código de uso unico fornecido pelo aplicativo e clique em "Cadastrar" para concluir a configuração.</p>
              <Input inputName={"MFAcode"} label={"Código autenticador*"} form={form} validators={{minLength: 6, maxLength: 6}} />
            </Modal.Body>
            <Modal.Footer>
              <Button text={"Cancelar"} color={ButtonColors.warn} onClick={() => setShowModal(false)} type={ButtonTypes.button} disabled={user.setMFACodeLoading} />
              <Button text={"Cadastrar"} color={ButtonColors.primary} type={ButtonTypes.submit} isLoading={user.setMFACodeLoading} />
            </Modal.Footer>
          </Form>
        </Modal>
      </Portal>
    </>
  );
}
