import { createSlice } from "@reduxjs/toolkit";
import { getUserPools } from "./pool-thunks";
import { PoolState } from "../classes/PoolState";

const initialState = new PoolState();

const poolSlice = createSlice({
  initialState,
  name: "pool",
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPools.fulfilled, (state, action) => {
        var values = action.payload.map((pool: any) => {
          return {
            value: pool.id,
            label: pool.poolName
          }
        });

        return { ...state, loading: false, pools: values };
      })
      .addCase(getUserPools.pending, (state) => {
        return { ...state, loading: true, error: null, pools: [] };
      })
      .addCase(getUserPools.rejected, (state, action) => {
        return { ...state, loading: false, error: action.error.message, pools: [] };
      })
  }
});

// export const { } = poolSlice.actions;
export default poolSlice.reducer;
