import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserService } from "../../services/UsersService";

export const getEditUser = createAsyncThunk("admin/getEditUser", async (prop: getEditUser) => {
    const response = await UserService.getUser(prop.id);
    const data = await response.data;
    if (data.birthdate) {
        data.birthdate = data.birthdate.split('T')[0]
    }
    return data;
});

export const adminUpdatePassword = createAsyncThunk("admin/updatePassword", async (prop: updatePassword) => {
    const response = await UserService.adminResetPassword(prop.id, prop.password);
    const data = await response.data;
    return data;
});

export const adminRemoveMFA = createAsyncThunk("admin/adminRemoveMFA", async (id: string) => {
    const response = await UserService.adminRemoveMFA(id);
    const data = await response.data;
    return data;
});

type getEditUser = {
    id: string;
}

type updatePassword = {
    id: string;
    password: string;
}