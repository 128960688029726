import { Form } from "react-bootstrap";
import style from "./inputDate.module.scss"

type InputProps = {
    inputName: string;
    label: string;
    disabled?: boolean;
    form: any;
};

export function InputDate({ inputName, label, form, disabled }: InputProps) {
    const { register, formState: { errors } } = form;

    return (
        <Form.Group className={`${label ? "mt-4 mb-2" : ""}`} controlId="formGroupName" style={{ width: "100%" }} >
            {label && <Form.Label>{label}</Form.Label>}
            <input id={inputName} className={`${style.datepicker} ${errors[inputName] ? style.fieldError : ''}`} disabled={disabled} style={{ width: "100%" }}
                type="date"
                {...register(inputName)}
            />
        </Form.Group>
    )
}