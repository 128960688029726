export default function decodeJWT(jwt: string) {
  if(!jwt) {
    return null;
  }

  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const decodedData = JSON.parse(window.atob(base64));

  return decodedData;
}