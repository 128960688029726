import style from './Button.module.scss';
import { IconType } from "react-icons";

export enum ButtonTypes {
  button = "button",
  submit = "submit",
  reset = "reset"
}

export enum ButtonColors {
  primary = "primary-color",
  warn = "warn-color",
  flat_primary = "flat-primary-color",
  flat_warn = "flat-warn-color",
  flat_success = "flat-success-color",
}

type ButtonProps = {
  type?: ButtonTypes;
  text?: string;
  onClick?: Function;
  Icon?: IconType;
  color?: ButtonColors;
  disabled?: boolean;
  isLoading?: boolean;
  smallButton?: boolean;
}

function Button({ type, text, onClick, Icon, color, disabled, isLoading, smallButton }: ButtonProps) {
  color = color || ButtonColors.primary;

  function buttonClick() {
    if (onClick) onClick();
  }

  let buttonContent = (
    <span>
      {text}
    </span>
  )

  if (Icon) {
    buttonContent = (
      <>
        <Icon className='mx-1' />
        <span>
          {text}
        </span>
      </>
    )
  }

  if (isLoading) {
    buttonContent = (
      <>
        <div style={{height: 28.8}}>
          <div className="spinner-border spinner-border-sm" role="status" />
        </div>
      </>
    )
    disabled = true;
  }

  let paddingStyle = ""

  if (smallButton) {
    paddingStyle = style.smallButton
  }

  return (
    <button type={type || "button"} className={`${style.botao} ${style[color]} ${paddingStyle} d-flex align-items-center`} onClick={buttonClick} disabled={disabled}>
      {buttonContent}
    </button>
  )

}

export default Button