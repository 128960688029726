import { Card, Container } from 'react-bootstrap';
import { AiOutlineReload } from 'react-icons/ai';
import { BiSolidError, BiSolidErrorAlt } from 'react-icons/bi';
import mobilemedImg from '../../assets/img/mobilemed-icon.svg'
import Button, { ButtonColors } from '../../components/Button';
import { useAppDispatch } from '../../redux/store';
import { getCurrentUser } from '../../redux/user/user-thunks';
import { FaSignOutAlt } from 'react-icons/fa';
import { SessionService } from '../../services/SessionService';

type ErrorProps = {
  errorMessage?: string;
};

export function ErrorPage({ errorMessage }: ErrorProps) {
  const dispatch = useAppDispatch();

  function reloadPage() {
    dispatch(getCurrentUser())
  }

  function logout() {
    SessionService.logout().then(() => { });
  }

  return (
    <Container>
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
        <Card style={{ padding: 24 }}>
          <Card.Title>Ocorreu um erro ao buscar suas informações</Card.Title>
          <div className="text-center mt-4">
            <div className="d-flex align-items-end justify-content-center ">
              <img src={mobilemedImg} alt="MobileMed" style={{ width: '100px' }} />
              <BiSolidError style={{ fontSize: '16px', color: "#EF4F4F" }} />
            </div>
          </div>
          {
            errorMessage ? 
              <div className="text-center mt-4">
                <p>{errorMessage}</p>
              </div> : <></>
          }
          <div className="d-flex flex-column align-items-center justify-content-center mt-4">
            <Button text="Tentar novamente" Icon={AiOutlineReload} color={ButtonColors.flat_primary} onClick={() => reloadPage()} />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <Button text="Sair" Icon={FaSignOutAlt} color={ButtonColors.flat_warn} onClick={() => logout()} />
          </div>
        </Card>
      </div>
    </Container>
  );
}