import styleApp from "../home/App.module.scss";
import Col from "react-bootstrap/Col";
import { Row } from "react-bootstrap";

import RegisteredAuthenticator from "../../components/RegisteredAuthenticator/RegisteredAuthenticator";
import { useSelector } from "react-redux";
import UpdatePassword from "../../components/UpdatePassword/UpdatePassword";
import Button, { ButtonColors } from "../../components/Button";
import { FaSignOutAlt } from "react-icons/fa";
import { SessionService } from "../../services/SessionService";
import RegisterMFA from "../../components/RegisterMFA/RegisterMFA";
import LoadingWrapper from "../../components/Loading";
import RemoveMFA from "../../components/RemoveMFA/RemoveMFA";
import Applications from "../../components/Applications/Applications";
import { MdCheck, MdEmail } from "react-icons/md";
import { UserService } from "../../services/UsersService";
import { toast } from "react-toastify";
import { useRef, useState } from "react";

function Security() {
  const user = useSelector((state: any) => state.userReducer.currentUser);
  const loading = useSelector((state: any) => state.userReducer.loading);
  const poolParams = window.location.pathname.split('/')
  const poolIdSession = useRef(poolParams[1])
  const poolTenant = useRef(poolParams[2])
  const [poolId, setPoolId] = useState(poolIdSession)
  const [tenant, setTenant] = useState(poolTenant)
  
  sessionStorage.setItem('currentPoolId', poolId.current)
  sessionStorage.setItem('currentTenant', tenant.current)

  function logoutButton() {
    SessionService.logout().then(() => { });
  }

  function sendEmailVerification() {
    UserService.sendVerificationEmail(user.id)
    .then(() => {
      toast.success("E-mail de verificação enviado com sucesso!")
    }).catch(() => {
      toast.error("Erro ao enviar o e-mail de verificação!")
    })
  }

  return (
    <>
      <LoadingWrapper isLoading={loading}>
        <Row sm={6}>
          <Col sm={6}>
            <label>Autenticação</label>
            <hr className={styleApp.line} />
            <Row style={{ paddingRight: "2em" }}>
              <div className={styleApp.card}>
                <Row>
                  <Col sm={9} className="d-flex align-items-center">
                    <b>Minha senha</b>
                  </Col>
                  <Col sm={3} className="d-flex justify-content-end">
                    <UpdatePassword id={user && user.id ? user.id : ""} />
                  </Col>
                </Row>
              </div>
            </Row>

            <Row style={{ paddingRight: "2em" }}>
              <div className={styleApp.card}>
                <Row>
                  <Col lg={7} md={6} className="d-flex align-items-center">
                    <b>Autenticação de dois fatores</b>
                  </Col>
                  {!user || !user.hasMFAEnabled ?
                    <Col lg={5} md={6} className="d-flex justify-content-end">
                      <p>
                        <RegisterMFA id={user && user.id ? user.id : ""} />
                      </p>
                    </Col> :
                    <Col lg={5} md={6} className="d-flex justify-content-end">
                      <p>
                        <RemoveMFA />
                      </p>
                    </Col>
                  }

                  <hr />
                </Row>
                <Row>
                  {!user || !user.hasMFAEnabled ?
                    <p><strong>Nenhum Dispositivo Cadastrado</strong></p> :
                    <RegisteredAuthenticator />
                  }
                </Row>
              </div>
            </Row>
            <Row style={{ paddingRight: "2em" }}>
              <div className={styleApp.card}>
                <Row>
                  <Col sm={9} className="d-flex align-items-center">
                    <b>Desconectar conta</b>
                  </Col>
                  <Col sm={3} className="d-flex justify-content-end">
                    <Button text={"Sair"} Icon={FaSignOutAlt} color={ButtonColors.flat_warn} onClick={() => logoutButton()} smallButton={true} />
                  </Col>
                </Row>
              </div>
            </Row>
            <Row style={{ paddingRight: "2em" }}>
              {
                user && user.isEmailVerified ? <div className={styleApp.card}>
                <Row>
                  <Col sm={5} className="d-flex align-items-center">
                    <b>Status do e-mail</b>
                  </Col>
                  <Col sm={7} className="d-flex justify-content-end">
                    <Button text={"E-mail verificado"} Icon={MdCheck} color={ButtonColors.flat_success} onClick={() => {}} smallButton={true} />
                  </Col>
                </Row>
              </div> :
                <div className={styleApp.card}>
                  <Row>
                    <Col sm={5} className="d-flex align-items-center">
                      <b>Verificar e-mail</b>
                    </Col>
                    <Col sm={7} className="d-flex justify-content-end">
                      <Button text={"Enviar verificação"} Icon={MdEmail} color={ButtonColors.flat_primary} onClick={() => sendEmailVerification()} smallButton={true} />
                    </Col>
                  </Row>
                </div>
              }
            </Row>
          </Col>
          <Col sm={6}>
            <label>Aplicações</label>
            <hr className={styleApp.line} />
            <Row style={{ paddingRight: "2em" }}>
              <div className={styleApp.card}>
                <Row>
                  <Col>
                  <Applications userId={user?.id}/>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </LoadingWrapper>
      
    </>
  );
}

export default Security;
