import style from "../../pages/home/App.module.scss";
import { Form } from "react-bootstrap";
import WarningCard from "../WarningCard";
type SelectValue = {
  value: string;
  label: string;
}

type InputProps = {
  inputName: string;
  label: string;
  disabled?: boolean;
  validators?: any;
  form: any;
  values: SelectValue[];
};

export function Select({ inputName, label, form, validators, disabled, values }: InputProps) {
  const { register, formState: { errors }, setValue } = form;
  const validatorsKeys = Object.keys(validators || {});

  return (
    <Form.Group className={`${label? "mt-4 mb-2" : ""}`} controlId="formGroupName" style={{ width: "100%" }}>
      {label && <Form.Label>{label}</Form.Label>}
      
      <select {...register(inputName, validators)} className={style.input} style={{ width: "100%", height: '35.2px' }} title={inputName} disabled={disabled}>
        {values.map((value) => {
          return <option key={value.value} value={value.value}>{value.label}</option>
        })}

      </select>

      {validatorsKeys.map((key) => {
        if (errors[inputName] && errors[inputName].type === key) {
          return <WarningCard key={key}>Há um erro</WarningCard>
        }

        return <div key={key}></div>
      })}
    </Form.Group>
  );
}
