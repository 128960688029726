import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import { PiBrowsers } from "react-icons/pi";
import { IconContext } from "react-icons";
import styleApp from "../../pages/home/App.module.scss";
import Accordion from 'react-bootstrap/Accordion';
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getUserSessions, removeAllUserSessions } from "../../redux/user/user-thunks";
import { useSelector } from "react-redux";
import RemoveSession from "../RemoveSession/RemoveSession";
import BrowserIcon from "../BrowserIcon/BrowserIcon";
import moment from"moment";
import Button, { ButtonColors, ButtonTypes } from "../Button";
import { toast } from "react-toastify";

function Applications({ userId }: { userId: any }) {
    const dispatch = useAppDispatch();
    const sessions = useAppSelector((state: any) => state.userReducer.userSessions);
    const removeUserSessionLoading: any = useSelector((state: any) => state.userReducer.removeUserSessionLoading)

    async function getSessions() {
        dispatch(getUserSessions(userId))
    }

    useEffect(() => {
        getSessions()
    }, []);

    async function handleSubmit(event: any) {
        event.preventDefault();
        await submitForm(event.target);
    }

    function submitForm(event: any) {
        return event;
    }

    function getTimestamp(timestamp: any) {
        let dataMoment = moment(timestamp).format("DD/MM/YYYY HH:mm")
        return dataMoment
    }

    function removeAllUserSessionsClick() {
        dispatch(removeAllUserSessions({userId})).then((resp: any) => {
            if (resp && resp.error) {
                return toast.error("Ocorreu um erro ao remover as sessões")
            }

            getSessions()

            return toast.success("Sessões desconectadas com sucesso")            
        })
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col sm={3} >
                        <span>Sessão</span>
                    </Col>
                    <Col md={5} sm={5} className="d-flex justify-content-center" >
                        <Row md={12} className="mx-1" style={{ paddingRight: "2em" }}>
                            <span>Detalhes da sessão</span>
                        </Row>
                    </Col>
                    <Col md={4} sm={4} className="d-flex justify-content-center">
                        <Button text="Remover todas as sessões" smallButton={true} color={ButtonColors.flat_warn} type={ButtonTypes.button} disabled={removeUserSessionLoading}
                            onClick={removeAllUserSessionsClick}
                        />
                    </Col>
                </Row>
                <hr className={styleApp.line} />

                <Accordion alwaysOpen style={{ maxHeight: '525px', contain: 'content', overflow: 'auto' }}>
                    {sessions?.map(
                        (session: any, index: any) =>
                            <div key={index}>
                                <Accordion.Item eventKey={index} >
                                    <Accordion.Header>
                                        <div className="d-flex justify-content-around col-sm-12" style={{ width: '95%' }}>
                                            <Col sm={2} md={3}>
                                                <BrowserIcon userAgent={session['browser']} />
                                            </Col>
                                            <Col sm={8} style={{ paddingLeft: '2em' }}>
                                                <span>
                                                    Primeiro login em {getTimestamp(session['created_at'])}
                                                    <br />
                                                    Último acesso em {getTimestamp(session['updated_at'])}
                                                    <br />
                                                    IP: {session['ip']}
                                                </span>
                                            </Col>
                                            <Col sm={2} style={{ marginRight: '2em', paddingTop: '0.5em' }}>
                                                <RemoveSession sessionId={session['id']} userId={userId} />
                                            </Col>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {session['applications'].map(
                                            (applications: any) =>
                                                <div className="d-flex justify-content-around col-sm-12 my-2 mx-1" style={{ width: '95%', paddingBottom: '15px' }}>
                                                    <Col sm={2} md={3}>
                                                        <IconContext.Provider value={{ color: "black" }}>
                                                            <span style={{ justifyContent: 'center', display: 'flex', fontSize: '2em', }}>
                                                                <PiBrowsers />
                                                            </span>
                                                        </IconContext.Provider>
                                                    </Col>
                                                    <Col sm={8} style={{ paddingLeft: '2em' }}>
                                                        <span>
                                                            Client: {applications['client_name']}
                                                            <br />
                                                            Logado em: {getTimestamp(applications['created_at'])}
                                                            <br />
                                                            Expira em: {getTimestamp(applications['expires_at'])}
                                                        </span>
                                                    </Col>
                                                    <Col sm={2} style={{ marginRight: '2em' }}>
                                                    </Col>
                                                </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </div>
                    )}
                </Accordion>
            </Form>
        </>
    );
}

export default Applications;
