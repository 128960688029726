export class CreateUser {
  email!: string 
  name!: string 
  gender!: string 
  phone!: string | null  // valide + on number
  identificationNumber!: string  // valide cpf
  internalCode!: string
  physicianIdentificationNumber!: string
  physicianState!: string
  poolId!: string
  password!: string
  isPasswordPermanent: boolean = false

  constructor(user: any) {
    this.email = user.email
    this.name = user.name
    this.gender = user.gender
    this.phone = user.phone && user.phone.length !== 0 ? `+${user.phone.replace(/[^a-zA-Z0-9]/g, '')}` : null;
    this.identificationNumber = user.identificationNumber && user.identificationNumber.length === 0 ? user.identificationNumber?.replace(/[^a-zA-Z0-9]/g, '') : null;
    this.internalCode = user.internalCode && user.internalCode.length === 0 ? user.internalCode : null
    this.physicianIdentificationNumber = user.physicianIdentificationNumber && user.physicianIdentificationNumber.length === 0 ? user.physicianIdentificationNumber : null
    this.physicianState = user.physicianState && user.physicianState.length === 0 ? user.physicianState : null
    this.poolId = user.poolId
    this.password = user.password
    this.isPasswordPermanent = user.isPasswordPermanent
  }
}