import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Input } from "../../components/Input/input";
import { ProfilePhoto } from "../../components/ProfilePhoto/profilePhoto";
import style from "../home/App.module.scss";
import { Select } from "../../components/Select/select";
import { validateCPF, validatePhone, validateRecoveryEmail } from "../../utils/validators";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getCurrentUser, getUserLogs, updateCurrentUser, updateUserPhoto } from "../../redux/user/user-thunks";
import { useState } from "react";
import { ModalDefault } from "../../components/ModalDefault/modal";
import Logs from "../../components/Logs";
import { toast } from "react-toastify";
import { UpdateUser } from "../../DTOs/UpdateUser";
import Button, { ButtonColors, ButtonTypes } from "../../components/Button";
import LoadingWrapper from "../../components/Loading";
import { InputDate } from "../../components/InputDate/inputDate";

function Information() {
  const dispatch = useAppDispatch();
  const user = useSelector((state: any) => state.userReducer.currentUser);
  const updateLoading = useSelector((state: any) => state.userReducer.updateCurrentUserLoading);
  const error = useSelector((state: any) => state.userReducer.error);
  let isloading: boolean = useAppSelector(
    (state: any) => state.userReducer.loading
  );

  const form = useForm({ defaultValues: user, delayError: 700 })

  // Reseta o form no primeiro loading e verifica se o usuário já está no objeto do form
  if (user && user.id && form.getValues().id !== user.id) {
    form.reset(user)
  }
  const { handleSubmit } = form;

  const onSubmit = async (data: any) => {
    data.phone = data.phone ? '+' + data.phone.split(' ').join('').split('-').join('').split('(').join('').split(')').join('').split('+').join('') : null;

    dispatch(updateCurrentUser(new UpdateUser(data)))
      .then((resp: any) => {
        if(resp && resp.error) {
          return toast.error("Ocorreu um erro ao atualizar o usuário")
        }

        dispatch(getCurrentUser())
        toast.success("Usuário atualizado com sucesso!")
      })
  } 

  const genderValues = [
    { value: "M", label: "Masculino" },
    { value: "F", label: "Feminino" },
    { value: "O", label: "Outros" }
  ]

  const useModal = () => {
    const [isShown, setIsShown] = useState<boolean>(false);
    const toggle = () => {
      setIsShown(!isShown)
    };
    return {
      isShown,
      toggle,
    };
  };

  const { isShown, toggle } = useModal();

  return (
    <Wrapper>
      <LoadingWrapper isLoading={isloading || (!isloading && !user && !error)} >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row sm={12}>
              <Col md={2} sm={3}>
                <label>Foto</label>
                <hr className={style.line} />
                <div className="d-flex justify-content-center align-items-center">
                  <ProfilePhoto id={"user-2"} currentProfilePhotoLink={user && user.photoLink ? user.photoLink : ""}
                    onProfilePhotoChange={function (profilePhotoData: FormData): void {
                      dispatch(updateUserPhoto({ user: user.id, picture: profilePhotoData }))
                        .then(() => {
                          toast.success("Foto atualizada com sucesso!")
                        })
                    }} />
                </div>
              </Col>
              <Col md={10} sm={9}>
                <label>Informações</label>
                <hr className={style.line} />
                <Row>
                  <Col md={6}>
                    <Input inputName={"email"} label={"E-mail"} form={form} validators={{ required: true }} disabled={true} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input inputName={"recoveryEmail"} label={"E-mail de recuperação"} form={form} validators={{ validate: validateRecoveryEmail(form)}}/>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input inputName={"name"} label={"Nome"} form={form} validators={{ required: true }} />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Input inputName={"identificationNumber"} label={"CPF"} form={form} mask="999.999.999-99" validators={{ validate: validateCPF }}></Input>
                  </Col>
                  <Col md={3}>
                    <Input inputName={"phone"} label={"Telefone"} mask="+99 (99) 99999-9999" form={form} validators={{ validate: validatePhone(12) }}></Input>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Select inputName={"gender"} label={"Gênero"} form={form} values={genderValues}></Select>
                  </Col>
                  <Col md={3}>
                    <InputDate inputName={"birthdate"} label={"Data de nascimento"} form={form} />
                  </Col>
                </Row>
                <Row>
                <Col md={3}>
                    <Input inputName={"internalCode"} label={"Código Interno"} form={form}></Input>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-end mt-3">
                      <div className="mx-2">
                        <Button text={"Logs de alteração"} type={ButtonTypes.button} onClick={toggle} color={ButtonColors.warn} disabled={updateLoading} />
                      </div>
                      <Button text={"Salvar"} type={ButtonTypes.submit} isLoading={updateLoading} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <ModalDefault
              isShown={isShown}
              hide={toggle}
              headerText='Histórico de Alteração'
              modalContent={
                <Logs userId={user?.id} />
              }
            />

          </Form>
      </LoadingWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /* width: 80%;
	margin: auto; */
`;

export default Information;
