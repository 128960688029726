import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "../classes/AuthState";

const initialState = new AuthState();

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      return { ...state, loading: false, currentUser: action.payload, isAuthenticated: !!action.payload};
    },
    setLoadingUser: (state, action) => {
      return { ...state, loading: true};
    },
    setUserManager: (state, action) => {
      return { ...state, userManager: action.payload };
    }
  },
});

export const { setAuthUser, setLoadingUser, setUserManager } = authSlice.actions;
export default authSlice.reducer;
